import {
  AmfResponseErrorArrayDto,
  AmfResponseErrorDto,
  HttpResponse,
} from "./Api";

const getErrorCode = (res: HttpResponse<unknown> | unknown): number | null => {
  if (res instanceof Response && "error" in res) {
    const bodyError = res.error as
      | AmfResponseErrorArrayDto
      | AmfResponseErrorDto;

    return bodyError.statusCode;
  }

  return null;
};

export default getErrorCode;
