import { useMutation } from "@tanstack/react-query";
import { ActivityLogBodyDto } from "api/Api";
import { useGetApiClient } from "api/get-api-client";

const useApiActivityLog = () => {
  const apiClient = useGetApiClient();

  return useMutation({
    mutationFn: async (data: ActivityLogBodyDto) => {
      await apiClient.activityLog.log(data);
    },
  });
};

export default useApiActivityLog;
