import { useQuery } from "@tanstack/react-query";
import { useGetApiClient } from "api/get-api-client";

const useApiAuth = () => {
  const apiClient = useGetApiClient();

  return useQuery({
    queryFn: async () => {
      return (await apiClient.auth.currentGet()).data;
    },
    queryKey: ["auth-new"],
  });
};

export default useApiAuth;
