import clsx from "clsx";
import { ReactNode } from "react";

import { Close } from "./close";
import css from "./header.module.scss";

type Props = {
  border?: boolean;
  close?: boolean;
  right?: ReactNode;
  title: ReactNode;
};

export const Header = ({
  border = true,
  close = true,
  right,
  title,
}: Props) => {
  return (
    <div className={clsx(css.header, border && css.border)}>
      <div className={css.title}>{title}</div>
      {right}
      {close && <Close inline />}
    </div>
  );
};
