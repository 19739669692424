import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";

import css from "./type.module.scss";

const CreditLow = () => {
  return (
    <div className={clsx(css.wrapper, css.yellow)}>
      <div className={css.content}>
        <Link className={css.link} to={paths.purchase()}>
          You're out of credits. <strong>Purchase credits now</strong>.
        </Link>
      </div>
    </div>
  );
};

export default CreditLow;
