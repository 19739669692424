import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { SearchSingleDto, SearchSingleListResponseDto } from "api/Api";
import { chunk } from "common/array";
import { useTrackEvent } from "contexts/tracking";
import { useCallback } from "react";
import { useAppDispatch } from "store";
import {
  searchSinglePageActions,
  searchSinglePageSelectors,
} from "store/reducers/search-single-page";

const getTrackingVariant = (search: SearchSingleDto) => {
  const result = search.resultList[0];

  if (!result) return "not-found";

  const type = result.verified ? "verified" : "not-verified";

  return result.isPaidFor ? type : `obfuscated-${type}`;
};

const useHandleSearchSingleUpdate = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const trackEvent = useTrackEvent();

  return useCallback(
    (update: SearchSingleDto) => {
      queryClient.setQueriesData<InfiniteData<SearchSingleListResponseDto>>(
        { queryKey: ["search-single-list"] },
        (prevData) => {
          if (!prevData) return prevData;

          let found = false;
          const perPage = prevData.pages[0].perPage;
          const searchList = prevData.pages
            .map((page) => page.searchList)
            .flat()
            .map((search) => {
              if (search.id === update.id) {
                found = true;
                return update;
              }
              return search;
            });

          if (!found) {
            searchList.unshift(update);
          }

          const pages = chunk<SearchSingleDto>(searchList, perPage)
            .slice(0, prevData.pageParams.length)
            .map((searchList) => ({
              perPage,
              searchList,
            }));

          return {
            pages,
            pageParams: prevData.pageParams,
          };
        },
      );

      if (update.isCompleted) {
        dispatch((dispatchInner, getState) => {
          const trackResultSearchIdList =
            searchSinglePageSelectors.trackResultSearchIdListGet(getState());
          if (trackResultSearchIdList.includes(update.id)) {
            dispatchInner(
              searchSinglePageActions.trackResultSearchIdRemove(update.id),
            );
            trackEvent("app-search-single-result", getTrackingVariant(update));
          }
        });
      }
    },
    [dispatch, queryClient, trackEvent],
  );
};

export default useHandleSearchSingleUpdate;
