import React from "react";
type Props = { className?: string };

export const BulkSearchIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.30839 1.69386C1.79868 1.20357 2.46365 0.928131 3.15702 0.928131H9.61444C9.87966 0.928131 10.134 1.03349 10.3215 1.22102L15.1646 6.06409C15.3521 6.25163 15.4575 6.50598 15.4575 6.7712V16.4573C15.4575 17.1507 15.1821 17.8157 14.6918 18.306C14.2015 18.7962 13.5365 19.0717 12.8432 19.0717H3.15702C2.46365 19.0717 1.79868 18.7962 1.30839 18.306C0.818104 17.8157 0.542664 17.1507 0.542664 16.4573V3.54249C0.542664 2.84912 0.818104 2.18414 1.30839 1.69386ZM3.15702 2.92813C2.99408 2.92813 2.83782 2.99286 2.7226 3.10807C2.60739 3.22329 2.54266 3.37955 2.54266 3.54249V16.4573C2.54266 16.6203 2.60739 16.7765 2.7226 16.8917C2.83782 17.007 2.99408 17.0717 3.15702 17.0717H12.8432C13.0061 17.0717 13.1624 17.007 13.2776 16.8917C13.3928 16.7765 13.4575 16.6203 13.4575 16.4573V7.18541L9.20023 2.92813H3.15702Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.3263 7.43048C7.708 7.08258 8.29185 7.08258 8.67355 7.43048L11.1397 9.67825C11.5479 10.0503 11.5772 10.6828 11.2051 11.0909C10.8331 11.4991 10.2006 11.5284 9.79245 11.1564L8.99993 10.434V13.8606C8.99993 14.4128 8.55221 14.8606 7.99993 14.8606C7.44764 14.8606 6.99993 14.4128 6.99993 13.8606V10.434L6.2074 11.1564C5.79922 11.5284 5.16674 11.4991 4.7947 11.0909C4.42267 10.6828 4.45197 10.0503 4.86015 9.67825L7.3263 7.43048Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
