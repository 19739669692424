import clsx from "clsx";
import { StateMessage } from "components/state-message";
import React, { ForwardedRef, forwardRef, useState } from "react";

import css from "./index.module.scss";

type Props = React.ComponentPropsWithoutRef<"textarea"> & {
  className?: string;
  size?: "small" | "medium" | "large";
  state?: "default" | "success" | "error";
  stateMessage?: string;
};

export const TextArea = forwardRef(
  (
    {
      className,
      size = "medium",
      state = "default",
      stateMessage,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
      <div className={css.wrapper}>
        <textarea
          {...props}
          className={clsx(
            css.textarea,
            css[size],
            css[state],
            hasFocus && css.hasFocus,
            className,
          )}
          ref={ref}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
        ></textarea>

        <StateMessage message={stateMessage} state={state} />
      </div>
    );
  },
);
