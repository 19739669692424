import React from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title?: string;
};

const Seo = ({ title }: Props) => {
  return (
    <Helmet>
      <title>{title ? `${title} - ` : ""}Anymail finder</title>
    </Helmet>
  );
};

export default Seo;
