import clsx from "clsx";
import useLogout from "common/auth/hooks/use-logout";
import { GearIcon } from "icons/gear-icon";
import { LogOutIcon } from "icons/log-out-icon";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import paths from "routes/paths";

type Props = {
  iconClassname?: string;
  itemActiveClassname?: string;
  itemClassname?: string;
};

export const NavUser = ({
  iconClassname,
  itemActiveClassname,
  itemClassname,
}: Props) => {
  const { pathname } = useLocation();
  const logout = useLogout();

  return (
    <>
      <Link
        className={clsx(
          itemClassname,
          pathname === paths.settingsAccount() && itemActiveClassname,
        )}
        to={paths.settingsAccount()}
      >
        <GearIcon className={iconClassname} />
        Account settings
      </Link>
      <a className={itemClassname} onClick={() => logout()}>
        <LogOutIcon className={iconClassname} />
        Log out
      </a>
    </>
  );
};
