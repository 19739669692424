import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export const useAuthBillingRefresh = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["auth-new"],
    });
    queryClient.invalidateQueries({
      queryKey: ["team-billing-info"],
    });
  }, [queryClient]);
};
