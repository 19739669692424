import React, { CSSProperties } from "react";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const CheckmarkIcon = ({ className, style }: Props) => {
  return (
    <svg
      className={className}
      style={style}
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.6553 0.334703C15.8696 0.549078 15.99 0.839793 15.99 1.14292C15.99 1.44605 15.8696 1.73676 15.6553 1.95114L6.50999 11.0964C6.29561 11.3108 6.0049 11.4311 5.70177 11.4311C5.39864 11.4311 5.10793 11.3108 4.89355 11.0964L0.320899 6.52379C0.112663 6.30819 -0.00256139 6.01942 4.32146e-05 5.71969C0.00264782 5.41995 0.122873 5.13323 0.334825 4.92128C0.546777 4.70933 0.833496 4.58911 1.13323 4.5865C1.43296 4.5839 1.72173 4.69912 1.93733 4.90736L5.70177 8.6718L14.0389 0.334703C14.2532 0.120393 14.544 0 14.8471 0C15.1502 0 15.4409 0.120393 15.6553 0.334703Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
