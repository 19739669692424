import "style/index.scss";
import "common/date";

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "app";
import AuthProvider from "common/auth/provider";
import Ws from "components/ws";
import { ExtensionProvider } from "contexts/extension";
import IntercomProvider from "contexts/intercom/provider";
import { ToastProvider } from "contexts/toast/provider";
import TrackingProvider from "contexts/tracking/provider";
import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import store from "store";
import { registerDocSizeListener } from "style/js/doc-size";

import getErrorCode from "./api/get-error-code";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      "Loading CSS chunk",
      "Loading chunk",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
    ],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const errorCode = getErrorCode(error);
        if (errorCode && [400, 401, 403, 404].includes(errorCode)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

registerDocSizeListener();

root.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <TrackingProvider>
            <ExtensionProvider>
              <AuthProvider>
                <Ws />
                <IntercomProvider>
                  <ToastProvider>
                    <App />
                  </ToastProvider>
                </IntercomProvider>
              </AuthProvider>
            </ExtensionProvider>
          </TrackingProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
);
