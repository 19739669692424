import { useGetApiClient } from "api/get-api-client";
import PageWrapper from "components/page-wrapper";
import Cookies from "js-cookie";
import Plausible from "plausible-tracker";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import TrackPageview from "./components/track-pageview";

type TrackingContextType = {
  trackEvent: (name: string, variant?: string) => void;
};

const TrackingContext = createContext<TrackingContextType>({
  trackEvent: () => {},
});

export const useTrackEvent = () => {
  const { trackEvent } = useContext(TrackingContext);

  return trackEvent;
};

const COOKIE_LANDING_PAGE = "landing_page_url";
const COOKIE_PARTNER = "amf_partner";

const TrackingProvider = ({ children }: PropsWithChildren<{}>) => {
  const [ready, readySet] = useState(false);
  const apiClient = useGetApiClient();
  const plausible = useMemo(
    () =>
      Plausible({
        domain: "newapp.anymailfinder.com,all-anymailfinder.com",
        trackLocalhost: false, // enable for debug
      }),
    [],
  );

  const context = useMemo<TrackingContextType>(
    () => ({
      trackEvent: async (name, variant) => {
        await apiClient.auth.eventTrack({
          n: name,
          v: variant,
        });
      },
    }),
    [apiClient],
  );

  useEffect(() => {
    if (ready) return;

    if (Cookies.get(COOKIE_LANDING_PAGE) == null) {
      Cookies.set(COOKIE_LANDING_PAGE, window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
    }

    const partner = new URLSearchParams(window.location.search).get("partner");
    if (partner) {
      Cookies.set(COOKIE_PARTNER, partner.toLowerCase(), {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
    }

    readySet(true);
  }, [ready]);

  if (!ready) {
    return <PageWrapper loading />;
  }

  return (
    <TrackingContext.Provider value={context}>
      {children}
      <TrackPageview plausibleTrackPageview={plausible.trackPageview} />
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
