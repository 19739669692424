import clsx from "clsx";
import React from "react";

import css from "./index.module.scss";

type Props = {
  className?: string;
  message: string | undefined;
  state?: "default" | "success" | "error";
};

export const StateMessage = ({ className, message, state }: Props) => (
  <>
    {message && (
      <span className={clsx(css.message, state && css[state], className)}>
        {message}
      </span>
    )}
  </>
);
