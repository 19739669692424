import { ToastItemOptionType } from "contexts/toast/item";
import { ReactNode, Reducer, useReducer } from "react";

type ToastItemType = {
  content?: ReactNode;
  id?: number;
  options?: ToastItemOptionType;
};

type ActionType = {
  payload?: ToastItemType | number;
  type: "close" | "close_all" | "open";
};

type StoreType = {
  nextId: number;
  toastList: { id: number; toast: ToastItemType }[];
};

const INITIAL_STATE: StoreType = {
  nextId: 0,
  toastList: [],
};

const reducer: Reducer<StoreType, ActionType> = (
  prevState,
  { payload, type },
) => {
  if (type === "close" && typeof payload === "number") {
    return {
      nextId: prevState.nextId,
      toastList: prevState.toastList.filter((item) => payload !== item.id),
    };
  }

  if (type === "close_all") {
    return {
      nextId: prevState.nextId,
      toastList: [],
    };
  }

  if (type === "open" && typeof payload === "object") {
    return {
      nextId: prevState.nextId + 1,
      toastList: [
        ...prevState.toastList,
        { toast: payload, id: prevState.nextId },
      ],
    };
  }

  return prevState;
};

const useToastReducer = () => {
  return useReducer(reducer, INITIAL_STATE);
};

export default useToastReducer;
