import clsx from "clsx";
import Spinner from "components/spinner";
import React, { PropsWithChildren } from "react";

import css from "./content.module.scss";

type Props = {
  className?: string;
  loading?: boolean;
};

export const Content = ({
  className,
  children,
  loading = false,
}: PropsWithChildren<Props>) => {
  if (loading) {
    return (
      <div className={clsx(css.content, css.loading, className)}>
        <Spinner />
      </div>
    );
  }

  return <div className={clsx(css.content, className)}>{children}</div>;
};
