import { CSSProperties } from "react";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const CrossIcon = ({ className, style }: Props) => {
  return (
    <svg
      className={className}
      style={style}
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.82808 1.90805C2.99895 0.736358 4.58677 0.0781363 6.24238 0.0781363C7.89799 0.0781363 9.48582 0.736358 10.6567 1.90805L37.461 28.7393L64.2652 1.90805C64.8412 1.31111 65.5302 0.834974 66.2919 0.507418C67.0537 0.179862 67.873 0.00744733 68.702 0.000235982C69.5311 -0.00697537 70.3532 0.151159 71.1206 0.465414C71.8879 0.77967 72.585 1.24375 73.1713 1.83058C73.7575 2.41741 74.2211 3.11524 74.535 3.88334C74.849 4.65144 75.007 5.47444 74.9998 6.30431C74.9926 7.13418 74.8203 7.9543 74.4931 8.71683C74.1659 9.47935 73.6902 10.169 73.0939 10.7455L46.2896 37.5768L73.0939 64.4081C74.2312 65.5868 74.8605 67.1656 74.8463 68.8043C74.8321 70.443 74.1754 72.0106 73.0178 73.1694C71.8602 74.3282 70.2942 74.9855 68.6571 74.9998C67.02 75.014 65.4428 74.384 64.2652 73.2455L37.461 46.4143L10.6567 73.2455C9.47911 74.384 7.90194 75.014 6.26485 74.9998C4.62777 74.9855 3.06177 74.3282 1.90413 73.1694C0.746497 72.0106 0.0898506 70.443 0.0756248 68.8043C0.061399 67.1656 0.690733 65.5868 1.82808 64.4081L28.6324 37.5768L1.82808 10.7455C0.65756 9.5735 0 7.98408 0 6.3268C0 4.66953 0.65756 3.0801 1.82808 1.90805V1.90805Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
