import { useQueryClient } from "@tanstack/react-query";
import { SearchBulkListResponseItemDto, SearchSingleDto } from "api/Api";
import { useAuthBillingRefresh } from "common/auth/hooks/use-auth-billing-refresh";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { authNewActions } from "store/reducers/auth-new";
import { websocketActions } from "store/reducers/websocket";

import useHandleSearchBulkDelete from "./hooks/use-handle-search-bulk-delete";
import useHandleSearchBulkUpdate from "./hooks/use-handle-search-bulk-update";
import useHandleSearchSingleDelete from "./hooks/use-handle-search-single-delete";
import useHandleSearchSingleUpdate from "./hooks/use-handle-search-single-update";
import css from "./index.module.scss";

const Ws = () => {
  const [errorCount, setErrorCount] = useState(0);
  const authBillingRefresh = useAuthBillingRefresh();
  const dispatch = useAppDispatch();
  const onSearchBulkDelete = useHandleSearchBulkDelete();
  const onSearchBulkUpdate = useHandleSearchBulkUpdate();
  const onSearchSingleDelete = useHandleSearchSingleDelete();
  const onSearchSingleUpdate = useHandleSearchSingleUpdate();
  const queryClient = useQueryClient();

  const socketClose = useCallback((ws: WebSocket) => {
    ws.onclose = () => {};
    ws.onerror = () => {};
    ws.onmessage = () => {};
    ws.onopen = () => {};

    ws.close();
  }, []);
  const socketConnect = useCallback(
    (onMessage: (message: any) => void) => {
      if (!process.env.REACT_APP_APIAPP_WS) {
        setErrorCount(100);
        throw new Error("Websocket URL not specified.");
      }

      const ws = new WebSocket(`${process.env.REACT_APP_APIAPP_WS}/ws`);
      ws.onerror = () => {
        setErrorCount((p) => p + 1);
      };
      ws.onopen = () => {
        dispatch(websocketActions.connectedSet(true));
        setErrorCount(0);
      };
      ws.onmessage = (e) => {
        if (e.data === "ping") {
          ws.send("pong");
          return;
        }

        onMessage(e.data);
      };

      ws.onclose = () => {
        setTimeout(() => {
          socketClose(ws);
          socketConnect(onMessage);
        }, 5000);
      };
    },
    [dispatch, socketClose],
  );

  useEffect(() => {
    socketConnect((json) => {
      const { data, type } = JSON.parse(json);

      if (type === "v1.domain-email.update") {
        queryClient.invalidateQueries({
          queryKey: ["search-domain-email-info"],
        });
      }
      if (type === "v1.search-bulk.delete") {
        onSearchBulkDelete(data as SearchBulkListResponseItemDto);
      }
      if (type === "v1.search-bulk.update") {
        onSearchBulkUpdate(data as SearchBulkListResponseItemDto);
      }
      if (type === "v1.search-single.delete") {
        onSearchSingleDelete(data as SearchSingleDto);
      }
      if (type === "v1.search-single.update") {
        onSearchSingleUpdate(data as SearchSingleDto);
      }
      if (type === "v1.team.billing-trial-fraud-detected") {
        dispatch(authNewActions.teamSubscriptionTrialFraudDetectedSet(true));
      }
      if (type === "v1.team.billing-update") {
        authBillingRefresh();
      }
      if (type === "v1.team.credit-update") {
        dispatch(authNewActions.creditLeftActiveSet(data.creditLeftActive));
        dispatch(authNewActions.creditLeftRolloverSet(data.creditLeftRollover));
      }
    });
  }, [
    authBillingRefresh,
    dispatch,
    onSearchBulkDelete,
    onSearchBulkUpdate,
    onSearchSingleDelete,
    onSearchSingleUpdate,
    queryClient,
    socketConnect,
  ]);

  if (errorCount >= 2) {
    return (
      <div className={css.wrapper}>
        <div className={css.content}>
          Connection Error: We're having trouble connecting to our servers.
          Please try refreshing the page or check your network connection.
        </div>
      </div>
    );
  }

  return null;
};

export default Ws;
