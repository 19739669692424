import { ApiIcon } from "icons/api-icon";
import { BulkSearchIcon } from "icons/bulk-search-icon";
import { SearchIcon } from "icons/search-icon";
import { VideoIcon } from "icons/video-icon";
import React, { cloneElement } from "react";
import { Link, useLocation } from "react-router-dom";
import paths from "routes/paths";

type Props = {
  iconClassname?: string;
  itemActiveClassname?: string;
  itemClassname?: string;
};

const ITEMS = [
  {
    icon: <SearchIcon />,
    label: "Search",
    path: paths.searchSingle(),
  },
  {
    icon: <BulkSearchIcon />,
    label: "Bulk search",
    path: paths.searchBulk(),
  },
  {
    icon: <ApiIcon />,
    label: "API",
    path: paths.settingsApi(),
  },
  {
    icon: <VideoIcon />,
    label: "Learn",
    path: paths.training(),
  },
];

export const NavMain = ({
  iconClassname,
  itemActiveClassname,
  itemClassname,
}: Props) => {
  const { pathname } = useLocation();

  return (
    <>
      {ITEMS.map((i) => (
        <Link
          className={`${itemClassname} ${
            pathname.startsWith(i.path) ? itemActiveClassname : ""
          }`}
          key={i.label}
          to={i.path}
        >
          {cloneElement(i.icon, { className: iconClassname })}
          {i.label}
        </Link>
      ))}
    </>
  );
};
