import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import {
  SearchBulkListResponseDto,
  SearchBulkListResponseItemDto,
} from "api/Api";
import { useCallback } from "react";

const useHandleSearchBulkDelete = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (update: SearchBulkListResponseItemDto) => {
      queryClient.removeQueries({
        queryKey: ["search-bulk", update.search.id],
      });

      queryClient.setQueriesData<InfiniteData<SearchBulkListResponseDto>>(
        { queryKey: ["search-bulk-list"] },
        (prevData) => {
          if (!prevData) return prevData;

          const pages = prevData.pages.map((page) => {
            const searchListFiltered = page.searchList.filter(
              (item) => update.search.id !== item.search.id,
            );

            if (searchListFiltered.length !== page.searchList.length) {
              return {
                perPage: page.perPage - 1,
                searchList: searchListFiltered,
              };
            }

            return page;
          });

          return {
            pages,
            pageParams: prevData.pageParams,
          };
        },
      );
    },
    [queryClient],
  );
};

export default useHandleSearchBulkDelete;
