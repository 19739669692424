import React from "react";

import css from "./index.module.scss";

type Props = {
  className?: string;
};

const Spinner = ({ className }: Props) => {
  return <div className={[css.spinner, className].join(" ")} />;
};

export default Spinner;
