import { useEffect } from "react";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

const Upscope = () => {
  const email = useAppSelector(authNewSelectors.userEmailGet);
  const idHash = useAppSelector(authNewSelectors.userIdGet);
  const name = useAppSelector(authNewSelectors.userNameGet);

  useEffect(() => {
    const upscope = (window as any).Upscope;

    const identities: string[] = [];
    if (email) identities.push(email);
    if (name) identities.push(name);

    upscope("updateConnection", {
      identities,
      uniqueId: idHash,
    });
  }, [email, idHash, name]);

  return null;
};

export default Upscope;
