import getErrorMessage from "api/get-error-message";
import useApiSearchSingleCreate from "api/search/single/use-api-search-single-create";
import { useToast } from "contexts/toast";
import { useTrackEvent } from "contexts/tracking";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";
import {
  searchSinglePageActions,
  searchSinglePageSelectors,
} from "store/reducers/search-single-page";
import { websocketSelectors } from "store/reducers/websocket";

const COOKIE_NAME = "www_search";

const WwwSearchHandler = () => {
  const creditCount = useAppSelector(authNewSelectors.creditLeftGet);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);
  const searchHistoryReady = useAppSelector(
    searchSinglePageSelectors.historyReadyGet,
  );
  const trackEvent = useTrackEvent();
  const websocketConnected = useAppSelector(websocketSelectors.connectedGet);
  const { mutateAsync: createSearch } = useApiSearchSingleCreate();
  const { addToast } = useToast();

  useEffect(() => {
    if (!searchHistoryReady || !websocketConnected) return;

    try {
      const cookieSearch = Cookies.get(COOKIE_NAME);
      if (!cookieSearch) return;

      Cookies.remove(COOKIE_NAME, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });

      const searchRaw = JSON.parse(cookieSearch);
      if (!searchRaw) return;

      if (
        typeof searchRaw.search_company_name !== "string" &&
        typeof searchRaw.search_domain !== "string"
      ) {
        return;
      }

      trackEvent("www-search-single-create");

      (async () => {
        let searchId: string | undefined = undefined;

        try {
          const res = await createSearch({
            searchCompanyName:
              typeof searchRaw.search_company_name === "string"
                ? searchRaw.search_company_name
                : undefined,
            searchDecisionMakerCategory:
              typeof searchRaw.search_decision_maker_category === "string"
                ? searchRaw.search_decision_maker_category
                : undefined,
            searchDomain:
              typeof searchRaw.search_domain === "string"
                ? searchRaw.search_domain
                : undefined,
            searchName:
              typeof searchRaw.search_name === "string"
                ? searchRaw.search_name
                : undefined,
          });

          searchId = res.data.id;
        } catch (e) {
          addToast(getErrorMessage(e), { appearance: "error" });
        }

        if (searchId && !isLoggedIn) {
          dispatch(searchSinglePageActions.onboardingSearchIdSet(searchId));
        }
      })();
    } catch (e) {}
  }, [
    addToast,
    createSearch,
    creditCount,
    dispatch,
    isLoggedIn,
    searchHistoryReady,
    trackEvent,
    websocketConnected,
  ]);

  return null;
};

export default WwwSearchHandler;
