import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetCurrentResponseDto,
  PromoDto,
  TeamDto,
  TeamSettingDto,
  UserDto,
  UserSettingDto,
} from "api/Api";
import dayjs from "dayjs";
import { RootState } from "store";

export type AuthNewStateType = {
  intercomHash?: string | null;
  loaded?: boolean;
  promo?: PromoDto | null;
  rewardfulReferrer?: string;
  team?: TeamDto;
  teamOwner?: UserDto;
  teamSetting?: TeamSettingDto;
  user?: UserDto;
  userRoleList?: string[];
  userSetting?: UserSettingDto;
};

const initialState: AuthNewStateType = {};

export const authNewSlice = createSlice({
  initialState,
  name: "authNew",
  reducers: {
    _set: (state, { payload }: PayloadAction<GetCurrentResponseDto>) => {
      state.intercomHash = payload.intercomHash;
      state.loaded = true;
      state.promo = payload.promo;
      state.team = payload.team || undefined;
      state.teamOwner = payload.teamOwner || undefined;
      state.teamSetting = payload.teamSetting || undefined;
      state.user = payload.user || undefined;
      state.userRoleList = payload.userRoleList || [];
      state.userSetting = payload.userSetting || undefined;
    },
    creditLeftActiveSet: (state, { payload }: PayloadAction<number>) => {
      if (state.team) {
        state.team.creditLeftActive = payload;
      }
    },
    creditLeftRolloverSet: (state, { payload }: PayloadAction<number>) => {
      if (state.team) {
        state.team.creditLeftRollover = payload;
      }
    },
    rewardfulReferrerSet: (state, { payload }: PayloadAction<string>) => {
      state.rewardfulReferrer = payload;
    },
    teamSubscriptionTrialFraudDetectedSet: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.team) {
        state.team.subscriptionTrialFraudDetected = payload;
      }
    },
  },
});

const promoGet = (state: RootState) => {
  const promo = state.authNew?.promo;
  if (!promo || dayjs.utc(promo.endAt).isBefore(dayjs().utc())) {
    return null;
  }

  return promo;
};

export const authNewSelectors = {
  _loadedGet: (state: RootState) => state.authNew.loaded === true,
  creditLeftActiveGet: (state: RootState) =>
    state.authNew.team?.creditLeftActive || 0,
  creditLeftGet: (state: RootState) =>
    (state.authNew.team?.creditLeftActive || 0) +
    (state.authNew.team?.creditLeftRollover || 0),
  creditLeftRolloverGet: (state: RootState) =>
    state.authNew.team?.creditLeftRollover || 0,
  creditLimitBulkGet: (state: RootState) =>
    state.authNew.team?.subscription?.creditLimitBulk || null,
  hasPurchase: (state: RootState) => state.authNew.team?.hasPurchase === true,
  loggedInGet: (state: RootState) =>
    Boolean(state.authNew.team) && Boolean(state.authNew.user),
  promoGet,
  rewardfulReferrerGet: (state: RootState) => state.authNew.rewardfulReferrer,
  searchCountApiGet: (state: RootState) =>
    state.authNew.team?.searchCountApi || 0,
  searchCountBulkGet: (state: RootState) =>
    state.authNew.team?.searchCountBulk || 0,
  subscriptionGet: (state: RootState) =>
    state.authNew.team?.subscription || null,
  teamEmailVerifiedGet: (state: RootState) =>
    state.authNew.teamOwner?.emailVerified === true,
  teamOwnerEmailGet: (state: RootState) => state.authNew.teamOwner?.email,
  teamOwnerIdGet: (state: RootState) => state.authNew.team?.ownerId,
  teamSettingCreditAutoRefillThresholdPercentageGet: (state: RootState) =>
    state.authNew.teamSetting?.creditAutoRefillThresholdPercentage || null,
  teamSettingLowCreditNotificationThresholdGet: (state: RootState) =>
    state.authNew.teamSetting?.lowCreditNotificationThreshold || 10,
  teamSubscriptionTrialFraudDetectedGet: (state: RootState) =>
    state.authNew.team?.subscriptionTrialFraudDetected === true,
  trialActiveGet: (state: RootState) =>
    state.authNew.team?.subscription?.trialEndAt != null,
  trialAvailableGet: (state: RootState) =>
    !state.authNew.team || state.authNew.team.subscriptionTrialAvailable,
  userEmailGet: (state: RootState) => state.authNew.user?.email,
  userIdGet: (state: RootState) => state.authNew.user?.id,
  userIntercomHash: (state: RootState) => state.authNew.intercomHash || null,
  userIsTeamOwner: (state: RootState) =>
    state.authNew.user?.id === state.authNew.team?.ownerId,
  userNameGet: (state: RootState) => state.authNew.user?.name,
  userRoleListGet: (state: RootState) => state.authNew.userRoleList,
  userSettingFileDoneNotificationGet: (state: RootState) =>
    state.authNew.userSetting?.fileDoneNotification || "on_if_last_in_queue",
};

export const authNewActions = authNewSlice.actions;
export default authNewSlice.reducer;
