import PageError from "components/page-error";
import PageWrapper from "components/page-wrapper";
import { Suspense, useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import paths from "./paths";
import routeList, { RouteType } from "./route-list";

const Router = () => {
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);
  const isTeamOwner = useAppSelector(authNewSelectors.userIsTeamOwner);

  const generator = useCallback(
    (path: string, route: RouteType) => {
      const requireAuth = ["require-team-owner", "required"].includes(
        route.auth || "",
      );

      let element = route.component;
      if (route.auth === "guest-only" && isLoggedIn) {
        element = <Navigate to={paths.searchSingle()} />;
      } else if (requireAuth && !isLoggedIn) {
        // using window.location.href is not the best fix ever but if we use path we can end up with param in the URL (eg ":id")
        const to =
          route.authRequiredRedirectTo === "signup"
            ? paths.authSignup({ successUrl: window.location.href })
            : paths.authLogin({ successUrl: window.location.href });

        element = <Navigate to={to} />;
      } else if (route.auth === "require-team-owner" && !isTeamOwner) {
        element = <Navigate to={paths.searchSingle()} />;
      }

      return (
        <Route element={element} key={path} path={path}>
          {Object.entries(route.children ?? []).map(([childPath, childRoute]) =>
            generator(childPath, childRoute),
          )}
        </Route>
      );
    },
    [isLoggedIn, isTeamOwner],
  );

  return (
    <Suspense fallback={<PageWrapper loading />}>
      <Routes>
        <Route element={<PageError code={404} />} path="*" />
        {Object.entries(routeList).map(([path, route]) =>
          generator(path, route),
        )}
      </Routes>
    </Suspense>
  );
};

export default Router;
