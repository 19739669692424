import { Button } from "components/button";
import { NavMain } from "components/header/nav/main";
import { NavUser } from "components/header/nav/user";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import css from "./index.module.scss";

type Props = {
  className?: string;
};

export const MobileMenu = ({ className }: Props) => {
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);

  return (
    <div className={[css.wrapper, className].join(" ")}>
      <div className={css.container}>
        <nav className={css.nav}>
          <NavMain
            iconClassname={css.navIcon}
            itemActiveClassname={css.active}
            itemClassname={css.navItem}
          />
        </nav>

        {isLoggedIn ? (
          <nav className={css.userMenu}>
            <NavUser
              iconClassname={css.userMenuIcon}
              itemActiveClassname={css.active}
              itemClassname={css.userMenuItem}
            />
          </nav>
        ) : (
          <Button appearance="outlined" as={Link} to={paths.authLogin()}>
            Login
          </Button>
        )}
      </div>
    </div>
  );
};
