import { useQueryClient } from "@tanstack/react-query";
import { useGetApiClient } from "api/get-api-client";
import { useCallback } from "react";
import paths from "routes/paths";

export const useStoreLogout = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["auth-new"] });
  }, [queryClient]);
};

const useLogout = () => {
  const api = useGetApiClient();

  return useCallback(async () => {
    await api.auth.logout();
    window.location.href = paths.authLogin();
  }, [api]);
};

export default useLogout;
