/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AmfResponseErrorDto {
  error: string;
  message: string;
  statusCode: number;
}

export interface ActivityLogBodyDto {
  description: string;
  type: string;
}

export type EmptyResponse = object;

export interface Get2FAInfoResponseDto {
  enabled: boolean;
}

export interface Enable2FAStartResponseDto {
  secret: string;
}

export interface AmfResponseErrorArrayDto {
  error: string;
  message: string[];
  statusCode: number;
}

export interface Enable2FACompleteBodyDto {
  token: string;
}

export interface Disable2FABodyDto {
  token: string;
}

export interface PromoDto {
  descriptionHtml: string;
  discountPercent: number;
  /** @format date-time */
  endAt?: string;
  id: string;
  name: string;
  noteHtml: string;
  onOneOff: boolean;
}

export interface TeamSubscriptionPlanDto {
  currency: "eur" | "gbp" | "usd";
  period: "month" | "quarter" | "year";
  cost: number;
  creditPerMonth: number;
  creditPerPeriod: number;
  description: string;
}

export interface TeamSubscriptionDto {
  /** @format date-time */
  billingPeriodStartedAt: string;
  /** @format date-time */
  cancelAt: string | null;
  creditLimitBulk: number | null;
  pastDue: boolean;
  pauseAllowed: boolean;
  /** @format date-time */
  pauseEndAt: string | null;
  plan: TeamSubscriptionPlanDto;
  /** @format date-time */
  trialEndAt: string | null;
}

export interface TeamDto {
  creditLeftActive: number;
  creditLeftRollover: number;
  hasPurchase: boolean;
  id: string;
  ownerId: string | null;
  searchCountApi: number;
  searchCountBulk: number;
  searchCountExtension: number;
  searchCountSingle: number;
  subscription: TeamSubscriptionDto | null;
  subscriptionTrialAvailable: boolean;
  subscriptionTrialFraudDetected: boolean;
}

export interface UserDto {
  email: string;
  emailVerified: boolean;
  id: string;
  name: string;
  searchCountBulk: number;
  searchCountExtension: number;
  searchCountSingle: number;
  teamId: string;
}

export interface TeamSettingDto {
  creditAutoRefillThresholdPercentage: number | null;
  lowCreditNotificationThreshold: number;
}

export interface UserSettingDto {
  fileDoneNotification: "off" | "on" | "on_if_last_in_queue";
}

export interface GetCurrentResponseDto {
  intercomHash: string | null;
  promo: PromoDto | null;
  team: TeamDto | null;
  teamOwner: UserDto | null;
  teamSetting: TeamSettingDto | null;
  user: UserDto | null;
  userRoleList: string[];
  userSetting: UserSettingDto | null;
}

export interface LoginBodyDto {
  auth2FAToken?: string;
  email: string;
  password: string;
}

export interface NoCreditOptionResponseDto {
  extension: boolean;
  trial: boolean;
}

export interface PasswordResetCompleteBodyDto {
  passwordNew: string;
}

export interface PasswordResetStartBodyDto {
  email: string;
}

export interface SignupBodyDto {
  email: string;
  name: string;
  password: string;
}

export interface InviteAcceptBodyDto {
  email?: string;
  password?: string;
}

export interface InviteGetResponseDto {
  email: string;
  teamName: string;
}

export interface OauthGoogleBodyDto {
  accessToken: string;
}

export interface ChargeInvoiceGetResponseDto {
  invoiceUrl: string;
}

export interface GetIntentParamsDto {
  rewardfulReferrer?: string;
  searchBulkId?: string;
  trial?: boolean;
}

export interface CountryDto {
  code: string;
  name: string;
}

export interface TeamBillingInfoDto {
  address: string | null;
  companyName: string | null;
  country: CountryDto | null;
  invoiceEmail: string | null;
  vatNumber: string | null;
  vatNumberWithCountry: string | null;
}

export interface PaymentMethodDto {
  description: string | null;
  id: string;
  type: string;
}

export interface BillingPlanDto {
  currency: "eur" | "gbp" | "usd";
  period: "month" | "quarter" | "year";
  abTest?: object;
  canRepeat: boolean;
  creditCount: number;
  id: string;
  name: string;
  priceDiscounted: number | null;
  priceFull: number;
  promoId?: string;
}

export interface GetIntentResponseDto {
  billingInfo: TeamBillingInfoDto;
  country: CountryDto;
  intent: {
    amount: number;
    clientSecret: string;
    currency: "eur" | "gbp" | "usd";
    type: "payment" | "setup";
  };
  lastPaymentMethod: PaymentMethodDto | null;
  plan: BillingPlanDto;
  planDownsellMonthly: BillingPlanDto | null;
  planUpsellYearly: BillingPlanDto | null;
  repeat: boolean;
  trialAvailable: boolean;
  vatRateCharged: number;
}

export interface GetInvoiceFailedLastResponseDto {
  invoiceId: string;
}

export interface GetInvoiceFailedByIdResponseDto {
  intent: {
    clientSecret: string;
  };
  invoice: {
    amount: number;
    /** @format date-time */
    created_at: string;
    currency: "eur" | "gbp" | "usd";
    id: string;
  };
}

export interface GetPaymentMethodUpdateIntentResponseDto {
  intent: {
    clientSecret: string;
  };
}

export interface GetPlanListResponseDto {
  abTest: "a" | "b";
  customOffer: BillingPlanDto | null;
  planList: BillingPlanDto[];
  searchBulkQuote: BillingPlanDto | null;
  trialAvailable: boolean;
}

export interface SubscriptionCancelBodyDto {
  feedback?: string;
  reason: string;
}

export interface GetGeoCountryListDto {
  countryVatRate: Record<string, number>;
  countryViesMap: Record<string, string>;
  countryList: CountryDto[];
}

export interface VisitCountGetResponseDto {
  count: number;
}

export interface LinkedinCompanyDto {
  countries?: string[];
  description?: string;
  domain?: string;
  industries?: string[];
  logoUrl?: string;
  name: string;
  staffCount?: number;
  universalName: string;
  urn: string;
  yearFounded?: number;
}

export interface VisitCompanyBodyDto {
  company: LinkedinCompanyDto;
  isSalesNavigator: boolean;
  linkedinId: number | null;
}

export interface VisitCompanyResponseDto {
  visitCount: number;
}

export interface LinkedinProfilePositionDto {
  company?: LinkedinCompanyDto;
  companyLogoUrl?: string;
  companyName: string;
  companyUniversalName?: string;
  endDate?: string;
  startDate?: string;
  title: string | null;
}

export interface LinkedinProfileDto {
  firstName: string;
  fullName?: string;
  geoCountryCode?: string;
  geoCountryName?: string;
  geoLocationName?: string;
  headline?: string;
  industryName?: string;
  lastName: string;
  linkedinUrl?: string;
  pictureUrl?: string;
  positionList: LinkedinProfilePositionDto[];
  profileId: string;
  publicIdentifier: string;
  salesNavigatorUrl?: string;
  summary?: string;
  urn: string;
}

export interface VisitProfileBodyDto {
  isSalesNavigator: boolean;
  linkedinId: number | null;
  profile: LinkedinProfileDto;
}

export interface VisitProfileResponseDto {
  visitCount: number;
}

export interface SearchBulkColumnIndexDto {
  companyName: number | null;
  domain: number | null;
  firstName: number | null;
  fullName: number | null;
  jobTitle: number | null;
  lastName: number | null;
}

export interface ExtensionSearchBulkCreateBodyDto {
  columnIndex: SearchBulkColumnIndexDto;
  rowList: string[][];
  searchName: string;
  sourceExtractionType?: string;
  sourcePageType?: string;
}

export interface ExtensionSearchBulkCreateResponseDto {
  id: string;
}

export interface SearchSingleResultDto {
  company: string | null;
  email: string | null;
  isPaidFor: boolean;
  linkedinUrl: string | null;
  name: string | null;
  notFoundReason: string | null;
  title: string | null;
  verified: boolean;
}

export interface SearchSingleDto {
  createdAt: string;
  domain: string | null;
  domainCount: number | null;
  hasSearchedDomain: boolean;
  id: string;
  isCompleted: boolean;
  isFailed: boolean;
  notFoundReason: string | null;
  resultList: SearchSingleResultDto[];
  searchCompanyName: string | null;
  searchDecisionMakerCategory: string | null;
  searchDomain: string | null;
  searchLinkedinUrl: string | null;
  searchName: string | null;
}

export interface ExtensionSearchSingleCreateBodyDto {
  extensionSourceUrl: string;
  searchCompanyName?: string;
  searchDomain?: string;
  searchName?: string;
  sourcePageType?: string;
}

export interface LogStoreBodyDto {
  data?: string;
  key: string;
}

export interface SessionGetResponseDto {
  linkedinVars: Record<string, string>;
  freeSearchLeft?: number;
  team: TeamDto | null;
  user: UserDto | null;
}

export interface SessionGetBodyDto {
  firstName?: string;
  lastName?: string;
  linkedinId?: number;
  occupation?: string;
  premiumSubscriber?: boolean;
  publicIdentifier?: string;
}

export interface ZapierSearchSingleCreateBodyDto {
  searchCompany: string;
  searchName: string;
}

export interface SearchSingleForExportDto {
  createdAt: string;
  createdByName: string;
  id: string;
  resultCompany: string | null;
  resultEmail: string | null;
  resultEmailFullyVerified: string | null;
  resultEmailList: string | null;
  resultEmailListFullyVerified: string | null;
  resultJobTitle: string | null;
  resultLinkedinUrl: string | null;
  resultName: string | null;
  resultType: object;
  searchedCompanyName: string | null;
  searchedDomain: string | null;
  searchedJobTitleList: string | null;
  searchedName: string | null;
  status: object;
}

export interface ZapierWebhookSubscribeBodyDto {
  hookUrl: string;
}

export interface ZapierWebhookUnsubscribeBodyDto {
  hookUrl: string;
}

export interface DomainCompleteResponseDto {
  domain: string;
}

export interface DomainStartBodyDto {
  domain: string;
  username: "admin" | "info" | "postmaster" | "webmaster" | "team" | "hello";
}

export interface EmailCompleteResponseDto {
  email: string;
}

export interface EmailStartBodyDto {
  email: string;
}

export interface SearchBulkCreateBodyDto {
  companyList: string[];
  decisionMakerCategory?: string;
  fileName: string;
}

export interface SearchBulkPaymentStatusDto {
  creditNotPaid: number;
  creditPaid: number;
  creditRequiredTotal: number;
}

export interface SearchBulkDto {
  columnIndex: SearchBulkColumnIndexDto;
  /** @format date-time */
  completedAt: string | null;
  count: {
    failed: number;
    foundNotVerified: number;
    foundVerified: number;
    notFound: number;
    total: number;
  };
  /** @format date-time */
  createdAt: string;
  decisionMakerCategory: string | null;
  fileName: string | null;
  hasPayment: boolean;
  id: string;
  paymentStatus: "full" | "partial" | null;
  /** @format date-time */
  startedRunningAt: string | null;
  status: "on_deck" | "queued" | "running" | "paused" | "completed" | "deleted";
  type: "company" | "decisionMaker" | "jobTitle" | "person";
  userId: string;
}

export interface SearchBulkGetResponseDto {
  payment: SearchBulkPaymentStatusDto;
  preview: string[][];
  search: SearchBulkDto;
}

export interface SearchBulkListResponseItemDto {
  search: SearchBulkDto;
}

export interface SearchBulkListResponseDto {
  perPage: number;
  searchList: SearchBulkListResponseItemDto[];
}

export interface SearchBulkMapBodyDto {
  columnIndex: SearchBulkColumnIndexDto;
  decisionMakerCategory?: string;
  fileName: string;
}

export interface SearchBulkRenameBodyDto {
  fileName: string;
}

export interface SearchBulkUploadResponseDto {
  fileName: string;
  id: string;
  preview: string[][];
}

export interface DomainEmailDownloadInfoDto {
  /**
   * Cost of the file in credits.
   * @example 123
   */
  costInCredit: number;
  /**
   * The requested domain.
   * @example "anymailfinder.com"
   */
  domain: string;
  /**
   * The number of email we have in our database for that domain.
   * @example 123
   */
  emailCount: number;
  /**
   * The status of your download (null = not requested, queued = we're getting the file ready, ready = the file is ready to be downloaded).
   * @example null
   */
  status: "queued" | "ready" | null;
}

export interface GetNameFromDomainBodyDto {
  domain: string;
}

export interface GetNameFromDomainResponseDto {
  companyName: string | null;
}

export interface SearchReportCreateBodyDto {
  comment: string;
  searchId: string;
}

export interface SearchSingleCreateBodyDto {
  searchCompanyName?: string;
  searchDecisionMakerCategory?: string;
  searchDomain?: string;
  searchLinkedinUrl?: string;
  searchName?: string;
}

export interface SearchSingleListResponseDto {
  perPage: number;
  searchList: SearchSingleDto[];
}

export interface BillingInfoGetResponseDto {
  billingInfo: TeamBillingInfoDto;
  paymentMethod: PaymentMethodDto | null;
}

export interface TeamBillingInfoUpdateBodyDto {
  address?: string | null;
  companyName?: string | null;
  countryCode?: string | null;
  vatNumber?: string | null;
}

export interface StripeChargeDto {
  amount: number;
  amountRefunded: number;
  currency: "eur" | "gbp" | "usd";
  date: string;
  disputed: boolean | null;
  id: string;
  invoiceUrl: string | null;
  status: object;
}

export interface ChargeListGetResponseDto {
  chargeList: StripeChargeDto[];
}

export interface CreditLeftGetResponseDto {
  subscriptionOnly: number;
}

export interface EnrichedTeamCreditRowDto {
  active: boolean;
  left: number;
  status: "available" | "expired" | "in_use" | "paused" | "used";
  added_at: string;
  expire_at: string | null;
  period_id: number | null;
  removed: number;
  total: number;
  type: "adjustment" | "linkedin_install" | "subscription" | "purchase" | "initial" | "reward";
  updated_at: string;
  used_not_verified: number;
  used_verified: number;
}

export interface UsageGetResponseDto {
  creditList: EnrichedTeamCreditRowDto[];
}

export interface MemberInviteDto {
  email: string;
  id: string;
  link: string;
}

export interface UserListDto {
  /** @format date-time */
  lastSeenAt: string | null;
  user: UserDto;
}

export interface TeamMemberListGetResponseDto {
  inviteList: MemberInviteDto[];
  userList: UserListDto[];
}

export interface MemberInviteSendBodyDto {
  email: string;
}

export interface ApiKeyGetResponseDto {
  key: string | null;
}

export interface ApiLogDto {
  endpoint: string;
  id: string;
  ip: string;
  isFree: boolean;
  /** @format date-time */
  requestAt: string;
  requestJson: string;
  responseJson: string;
  statusCode: number;
  webhookUrl: string | null;
}

export interface ApiLogListGetResponseDto {
  logList: ApiLogDto[];
  perPage: number;
}

export interface BillingPatchBodyDto {
  /**
   * @min 0
   * @max 10
   */
  autoRefillThresholdPercentage?: number | null;
  /** @min 0 */
  lowCreditNotificationThreshold?: number;
}

export interface TrainingVideoDto {
  category: string;
  description: string;
  id: string;
  thumbnailUrl: string;
  title: string;
  videoUrl: string;
}

export interface GetTrainingListResponseDto {
  videoList: TrainingVideoDto[];
  watchedVideoIdList: string[];
}

export interface EmailUpdateBodyDto {
  email: string;
  password: string;
}

export interface EmailVerifyBodyDto {
  email: string;
}

export interface PasswordUpdateBodyDto {
  currentPassword: string;
  newPassword: string;
}

export interface UserInfoUpdateBodyDto {
  name: string;
}

export interface CreateUserFeedbackBodyDto {
  /**
   * @min 1
   * @max 10
   */
  rate: number;
  type: "api" | "bulk";
}

export interface UserFeedbackDto {
  /** @format date-time */
  createdAt: string;
  rate: number;
  type: string;
  userId: number;
}

export interface GetUserFeedbackResponseDto {
  api: UserFeedbackDto | null;
  bulk: UserFeedbackDto | null;
}

export interface ExtensionLogStoreBodyDto {
  data?: string;
  key: string;
}

export interface NotificationPatchBodyDto {
  fileDoneNotification: "off" | "on" | "on_if_last_in_queue";
}

export interface UserSessionDto {
  device: string;
  id: string;
  /** @format date-time */
  lastSeenAt: string;
}

export interface SessionListResponseDto {
  current: UserSessionDto;
  list: UserSessionDto[];
}

export interface ZapierIntegrationGetKeyResponseDto {
  key: string;
}

export interface ZapierIntegrationDto {
  /** @format date-time */
  createdAt: string;
  id: string;
  isUsed: boolean;
  /** @format date-time */
  lastUsedAt: string | null;
}

export interface ZapierIntegrationListResponseDto {
  integrationList: ZapierIntegrationDto[];
}

export interface ZapierKeyGetResponseDto {
  id: string;
}

export interface MailcoachBodyDto {
  email: string;
  event: string;
}

export interface PostmarkBodyDto {
  Email: string;
  RecordType: string;
  Type: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Anymail finder App Api
 * @version 1.0.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  activityLog = {
    /**
     * No description
     *
     * @tags activityLog
     * @name Log
     * @request POST:/activity-log/log
     * @secure
     */
    log: (data: ActivityLogBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/activity-log/log`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name Get2FaInfo
     * @request GET:/auth/2fa
     * @secure
     */
    get2FaInfo: (params: RequestParams = {}) =>
      this.request<Get2FAInfoResponseDto, AmfResponseErrorDto>({
        path: `/auth/2fa`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Disable2Fa
     * @request DELETE:/auth/2fa
     * @secure
     */
    disable2Fa: (data: Disable2FABodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/auth/2fa`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Enable2FaStart
     * @request GET:/auth/2fa/enable
     * @secure
     */
    enable2FaStart: (params: RequestParams = {}) =>
      this.request<Enable2FAStartResponseDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/auth/2fa/enable`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Enable2FaComplete
     * @request POST:/auth/2fa/enable
     * @secure
     */
    enable2FaComplete: (data: Enable2FACompleteBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/auth/2fa/enable`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name CurrentGet
     * @request GET:/auth
     * @secure
     */
    currentGet: (params: RequestParams = {}) =>
      this.request<GetCurrentResponseDto, any>({
        path: `/auth`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name EventTrack
     * @request GET:/auth/track
     * @secure
     */
    eventTrack: (
      query: {
        n: string;
        v?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyResponse, any>({
        path: `/auth/track`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Login
     * @request POST:/auth/login
     * @secure
     */
    login: (data: LoginBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Logout
     * @request DELETE:/auth/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/auth/logout`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name NoCreditOptionGet
     * @request GET:/auth/no-credit-option
     * @secure
     */
    noCreditOptionGet: (params: RequestParams = {}) =>
      this.request<NoCreditOptionResponseDto, any>({
        path: `/auth/no-credit-option`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name PasswordResetComplete
     * @request POST:/auth/password-reset/{token}
     * @secure
     */
    passwordResetComplete: (token: string, data: PasswordResetCompleteBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/auth/password-reset/${token}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name PasswordResetStart
     * @request POST:/auth/password-reset
     * @secure
     */
    passwordResetStart: (data: PasswordResetStartBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/auth/password-reset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Signup
     * @request POST:/auth/signup
     * @secure
     */
    signup: (data: SignupBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/auth/signup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name InviteAccept
     * @request POST:/auth/invite/{token}
     * @secure
     */
    inviteAccept: (token: string, data: InviteAcceptBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/auth/invite/${token}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name InviteDelete
     * @request DELETE:/auth/invite/{token}
     * @secure
     */
    inviteDelete: (token: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/auth/invite/${token}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name InviteGet
     * @request GET:/auth/invite/{token}
     * @secure
     */
    inviteGet: (token: string, params: RequestParams = {}) =>
      this.request<InviteGetResponseDto, AmfResponseErrorDto>({
        path: `/auth/invite/${token}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth/oauth
     * @name OauthGoogle
     * @request POST:/auth/google
     * @secure
     */
    oauthGoogle: (data: OauthGoogleBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/auth/google`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  billing = {
    /**
     * No description
     *
     * @tags billing
     * @name ChargeInvoiceGet
     * @request GET:/billing/charge/{chargeId}/invoice
     */
    chargeInvoiceGet: (chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeInvoiceGetResponseDto, AmfResponseErrorDto>({
        path: `/billing/charge/${chargeId}/invoice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name GetIntent
     * @request POST:/billing/checkout/{planId}
     * @secure
     */
    getIntent: (planId: string, data: GetIntentParamsDto, params: RequestParams = {}) =>
      this.request<GetIntentResponseDto, AmfResponseErrorDto>({
        path: `/billing/checkout/${planId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name GetInvoiceFailedLast
     * @request GET:/billing/invoice/failed
     * @secure
     */
    getInvoiceFailedLast: (params: RequestParams = {}) =>
      this.request<GetInvoiceFailedLastResponseDto, AmfResponseErrorDto>({
        path: `/billing/invoice/failed`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name GetInvoiceFailedById
     * @request GET:/billing/invoice/failed/{invoiceId}
     * @secure
     */
    getInvoiceFailedById: (invoiceId: string, params: RequestParams = {}) =>
      this.request<GetInvoiceFailedByIdResponseDto, AmfResponseErrorDto>({
        path: `/billing/invoice/failed/${invoiceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name GetPaymentMethodUpdateIntent
     * @request POST:/billing/payment-method
     * @secure
     */
    getPaymentMethodUpdateIntent: (params: RequestParams = {}) =>
      this.request<GetPaymentMethodUpdateIntentResponseDto, AmfResponseErrorDto>({
        path: `/billing/payment-method`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name GetPlanList
     * @request GET:/billing/plan
     * @secure
     */
    getPlanList: (
      query?: {
        currency?: "eur" | "gbp" | "usd";
        searchBulkId?: string;
        trial?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPlanListResponseDto, any>({
        path: `/billing/plan`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HandleStripeWebhook
     * @request POST:/billing/stripe/webhook
     */
    handleStripeWebhook: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/billing/stripe/webhook`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name SubscriptionCancel
     * @request POST:/billing/subscription/cancel
     * @secure
     */
    subscriptionCancel: (data: SubscriptionCancelBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/billing/subscription/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name SubscriptionReactivate
     * @request DELETE:/billing/subscription/cancel
     * @secure
     */
    subscriptionReactivate: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/billing/subscription/cancel`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name SubscriptionPause
     * @request POST:/billing/subscription/pause
     * @secure
     */
    subscriptionPause: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/billing/subscription/pause`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name SubscriptionUnpause
     * @request DELETE:/billing/subscription/pause
     * @secure
     */
    subscriptionUnpause: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/billing/subscription/pause`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name SubscriptionRefill
     * @request POST:/billing/subscription/refill
     * @secure
     */
    subscriptionRefill: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/billing/subscription/refill`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing
     * @name TrialConvert
     * @request POST:/billing/subscription/convert
     * @secure
     */
    trialConvert: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/billing/subscription/convert`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  data = {
    /**
     * No description
     *
     * @tags data
     * @name GetGeoCountryList
     * @request GET:/data/geo/country
     * @secure
     */
    getGeoCountryList: (params: RequestParams = {}) =>
      this.request<GetGeoCountryListDto, any>({
        path: `/data/geo/country`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  extension = {
    /**
     * No description
     *
     * @tags extension
     * @name LinkedinVisitCountGet
     * @request GET:/extension/linkedin/visit/count
     * @secure
     */
    linkedinVisitCountGet: (
      query: {
        linkedinId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<VisitCountGetResponseDto, any>({
        path: `/extension/linkedin/visit/count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name LinkedinVisitCompany
     * @request POST:/extension/linkedin/visit/company
     * @secure
     */
    linkedinVisitCompany: (data: VisitCompanyBodyDto, params: RequestParams = {}) =>
      this.request<VisitCompanyResponseDto, AmfResponseErrorArrayDto>({
        path: `/extension/linkedin/visit/company`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name LinkedinVisitProfile
     * @request POST:/extension/linkedin/visit/profile
     * @secure
     */
    linkedinVisitProfile: (data: VisitProfileBodyDto, params: RequestParams = {}) =>
      this.request<VisitProfileResponseDto, AmfResponseErrorArrayDto>({
        path: `/extension/linkedin/visit/profile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name SearchBulkCreate
     * @request POST:/extension/search/bulk
     * @secure
     */
    searchBulkCreate: (data: ExtensionSearchBulkCreateBodyDto, params: RequestParams = {}) =>
      this.request<ExtensionSearchBulkCreateResponseDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/extension/search/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name SearchSingleGet
     * @request GET:/extension/search/single/{searchId}
     * @secure
     */
    searchSingleGet: (searchId: string, params: RequestParams = {}) =>
      this.request<SearchSingleDto, AmfResponseErrorDto>({
        path: `/extension/search/single/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name SearchSingleCreate
     * @request POST:/extension/search/single
     * @secure
     */
    searchSingleCreate: (
      query: {
        linkedinId: number;
      },
      data: ExtensionSearchSingleCreateBodyDto,
      params: RequestParams = {},
    ) =>
      this.request<SearchSingleDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/extension/search/single`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name LogStore
     * @request POST:/extension/session/log
     * @secure
     */
    logStore: (data: LogStoreBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto>({
        path: `/extension/session/log`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name SessionGetDeprecated
     * @request GET:/extension/session
     * @secure
     */
    sessionGetDeprecated: (params: RequestParams = {}) =>
      this.request<SessionGetResponseDto, AmfResponseErrorArrayDto>({
        path: `/extension/session`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags extension
     * @name SessionGet
     * @request POST:/extension/session
     * @secure
     */
    sessionGet: (data: SessionGetBodyDto, params: RequestParams = {}) =>
      this.request<SessionGetResponseDto, AmfResponseErrorArrayDto>({
        path: `/extension/session`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  integration = {
    /**
     * No description
     *
     * @name ZapierAuth
     * @request GET:/integration/zapier/auth
     */
    zapierAuth: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/integration/zapier/auth`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name ZapierSearchSingleCreate
     * @request POST:/integration/zapier/search/single
     */
    zapierSearchSingleCreate: (data: ZapierSearchSingleCreateBodyDto, params: RequestParams = {}) =>
      this.request<SearchSingleForExportDto, any>({
        path: `/integration/zapier/search/single`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ZapierWebhookData
     * @request GET:/integration/zapier/webhook/{type}
     */
    zapierWebhookData: (type: string, params: RequestParams = {}) =>
      this.request<SearchSingleForExportDto[], any>({
        path: `/integration/zapier/webhook/${type}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ZapierWebhookSubscribe
     * @request POST:/integration/zapier/webhook/{type}
     */
    zapierWebhookSubscribe: (type: string, data: ZapierWebhookSubscribeBodyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/integration/zapier/webhook/${type}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ZapierWebhookUnsubscribe
     * @request DELETE:/integration/zapier/webhook/{type}
     */
    zapierWebhookUnsubscribe: (type: string, data: ZapierWebhookUnsubscribeBodyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/integration/zapier/webhook/${type}`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  optOut = {
    /**
     * No description
     *
     * @tags optOut
     * @name DomainComplete
     * @request POST:/opt_out/domain/{token}
     * @secure
     */
    domainComplete: (token: string, params: RequestParams = {}) =>
      this.request<DomainCompleteResponseDto, AmfResponseErrorDto>({
        path: `/opt_out/domain/${token}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags optOut
     * @name DomainStart
     * @request POST:/opt_out/domain
     * @secure
     */
    domainStart: (data: DomainStartBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto>({
        path: `/opt_out/domain`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags optOut
     * @name EmailComplete
     * @request POST:/opt_out/email/{token}
     * @secure
     */
    emailComplete: (token: string, params: RequestParams = {}) =>
      this.request<EmailCompleteResponseDto, AmfResponseErrorDto>({
        path: `/opt_out/email/${token}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags optOut
     * @name EmailStart
     * @request POST:/opt_out/email
     * @secure
     */
    emailStart: (data: EmailStartBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto>({
        path: `/opt_out/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  paging = {
    /**
     * No description
     *
     * @name BingDatacloud
     * @request GET:/paging/bing-datacloud
     */
    bingDatacloud: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/paging/bing-datacloud`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name SerpGoogle
     * @request GET:/paging/google-webshare
     */
    serpGoogle: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/paging/google-webshare`,
        method: "GET",
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags search
     * @name SearchBulkCreate
     * @request POST:/search/bulk/create
     * @secure
     */
    searchBulkCreate: (data: SearchBulkCreateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/bulk/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkDelete
     * @request DELETE:/search/bulk/{searchId}
     * @secure
     */
    searchBulkDelete: (searchId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkGet
     * @request GET:/search/bulk/{searchId}
     * @secure
     */
    searchBulkGet: (searchId: string, params: RequestParams = {}) =>
      this.request<SearchBulkGetResponseDto, AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkDownload
     * @request GET:/search/bulk/{searchId}/download
     * @secure
     */
    searchBulkDownload: (
      searchId: string,
      query?: {
        /** Include partially verified emails ("true" or "false", defaults to "true"). */
        partiallyVerified?: string;
        /** Include not found emails ("true" or "false", defaults to "true"). */
        notFound?: string;
        /** Include fully verified emails ("true" or "false", defaults to "true"). */
        fullyVerified?: string;
        /** Format of extraction ("company-one-email-per-line" or empty for default). */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}/download`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkList
     * @request GET:/search/bulk
     * @secure
     */
    searchBulkList: (
      query?: {
        createdBefore?: string;
        createdBy?: "owner" | "team";
        searchNameFilter?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchBulkListResponseDto, AmfResponseErrorDto>({
        path: `/search/bulk`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkMap
     * @request POST:/search/bulk/{searchId}/map
     * @secure
     */
    searchBulkMap: (searchId: string, data: SearchBulkMapBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}/map`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkPay
     * @request POST:/search/bulk/{searchId}/pay
     * @secure
     */
    searchBulkPay: (searchId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}/pay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkRename
     * @request POST:/search/bulk/{searchId}/rename
     * @secure
     */
    searchBulkRename: (searchId: string, data: SearchBulkRenameBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/search/bulk/${searchId}/rename`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchBulkUpload
     * @request POST:/search/bulk/upload
     * @secure
     */
    searchBulkUpload: (params: RequestParams = {}) =>
      this.request<SearchBulkUploadResponseDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/bulk/upload`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name DomainEmailDownload
     * @request GET:/search/company/domain/{domain}/email/download
     * @secure
     */
    domainEmailDownload: (domain: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/search/company/domain/${domain}/email/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name DomainEmailPay
     * @request POST:/search/company/domain/{domain}/email/pay
     * @secure
     */
    domainEmailPay: (domain: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/company/domain/${domain}/email/pay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name DomainEmailInfo
     * @request GET:/search/company/domain/{domain}/email
     * @secure
     */
    domainEmailInfo: (domain: string, params: RequestParams = {}) =>
      this.request<DomainEmailDownloadInfoDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/company/domain/${domain}/email`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name GetCompanyNameFromDomain
     * @request POST:/search/company/name
     * @secure
     */
    getCompanyNameFromDomain: (data: GetNameFromDomainBodyDto, params: RequestParams = {}) =>
      this.request<GetNameFromDomainResponseDto, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/company/name`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchReportCreate
     * @request POST:/search/report
     * @secure
     */
    searchReportCreate: (data: SearchReportCreateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/search/report`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleCreate
     * @request POST:/search/single
     * @secure
     */
    searchSingleCreate: (data: SearchSingleCreateBodyDto, params: RequestParams = {}) =>
      this.request<SearchSingleDto, AmfResponseErrorArrayDto>({
        path: `/search/single`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleList
     * @request GET:/search/single
     * @secure
     */
    searchSingleList: (
      query?: {
        createdBefore?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchSingleListResponseDto, AmfResponseErrorArrayDto>({
        path: `/search/single`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleCreateCompanyOnlySearch
     * @request POST:/search/single/{searchId}/create-company-only-search
     * @secure
     */
    searchSingleCreateCompanyOnlySearch: (searchId: string, params: RequestParams = {}) =>
      this.request<SearchSingleDto, any>({
        path: `/search/single/${searchId}/create-company-only-search`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleDelete
     * @request DELETE:/search/single/{searchId}
     * @secure
     */
    searchSingleDelete: (searchId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/search/single/${searchId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleDownloadCsv
     * @request GET:/search/single/download/csv
     * @secure
     */
    searchSingleDownloadCsv: (
      query: {
        format?: string | null;
        owner: "team" | "user";
        since: "always" | "last_download" | "month_ago" | "today" | "week_ago";
      },
      params: RequestParams = {},
    ) =>
      this.request<string, AmfResponseErrorDto>({
        path: `/search/single/download/csv`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search
     * @name SearchSingleReveal
     * @request POST:/search/single/{searchId}/reveal
     * @secure
     */
    searchSingleReveal: (searchId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, any>({
        path: `/search/single/${searchId}/reveal`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  team = {
    /**
     * No description
     *
     * @tags team
     * @name BillingInfoGet
     * @request GET:/team/billing-info
     * @secure
     */
    billingInfoGet: (params: RequestParams = {}) =>
      this.request<BillingInfoGetResponseDto, AmfResponseErrorDto>({
        path: `/team/billing-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name BillingInfoUpdate
     * @request POST:/team/billing-info
     * @secure
     */
    billingInfoUpdate: (data: TeamBillingInfoUpdateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/team/billing-info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name ChargeListGet
     * @request GET:/team/charge/history
     * @secure
     */
    chargeListGet: (params: RequestParams = {}) =>
      this.request<ChargeListGetResponseDto, AmfResponseErrorDto>({
        path: `/team/charge/history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name CreditLeftGet
     * @request GET:/team/credit/left
     * @secure
     */
    creditLeftGet: (params: RequestParams = {}) =>
      this.request<CreditLeftGetResponseDto, AmfResponseErrorDto>({
        path: `/team/credit/left`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name CreditListGet
     * @request GET:/team/credit/list
     * @secure
     */
    creditListGet: (params: RequestParams = {}) =>
      this.request<UsageGetResponseDto, AmfResponseErrorDto>({
        path: `/team/credit/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name TeamMemberListGet
     * @request GET:/team/member
     * @secure
     */
    teamMemberListGet: (params: RequestParams = {}) =>
      this.request<TeamMemberListGetResponseDto, AmfResponseErrorDto>({
        path: `/team/member`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name TeamMemberDelete
     * @request DELETE:/team/member/{userId}
     * @secure
     */
    teamMemberDelete: (userId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/team/member/${userId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name MemberInviteSend
     * @request POST:/team/member/invite
     * @secure
     */
    memberInviteSend: (data: MemberInviteSendBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/team/member/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name MemberInviteDelete
     * @request DELETE:/team/member/invite/{inviteId}
     * @secure
     */
    memberInviteDelete: (inviteId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/team/member/invite/${inviteId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name ApiKeyGet
     * @request GET:/team/api/key
     * @secure
     */
    apiKeyGet: (params: RequestParams = {}) =>
      this.request<ApiKeyGetResponseDto, AmfResponseErrorDto>({
        path: `/team/api/key`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name ApiKeyRefresh
     * @request POST:/team/api/key-refresh
     * @secure
     */
    apiKeyRefresh: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/team/api/key-refresh`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name ApiLogListGet
     * @request GET:/team/api/log
     * @secure
     */
    apiLogListGet: (
      query?: {
        beforeId?: string;
        dateEnd?: string;
        dateStart?: string;
        isFree?: string;
        statusCode?: string;
        url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiLogListGetResponseDto, AmfResponseErrorDto>({
        path: `/team/api/log`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name BillingPatch
     * @request PATCH:/team/setting/billing
     * @secure
     */
    billingPatch: (data: BillingPatchBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/team/setting/billing`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags team
     * @name DeleteTeam
     * @request DELETE:/team/setting/team
     * @secure
     */
    deleteTeam: (params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/team/setting/team`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  training = {
    /**
     * No description
     *
     * @tags training
     * @name GetTrainingVideoList
     * @request GET:/training
     * @secure
     */
    getTrainingVideoList: (params: RequestParams = {}) =>
      this.request<GetTrainingListResponseDto, any>({
        path: `/training`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags training
     * @name SetTrainingVideoWatched
     * @request POST:/training/{videoId}/ended
     * @secure
     */
    setTrainingVideoWatched: (videoId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto>({
        path: `/training/${videoId}/ended`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name EmailUpdate
     * @request POST:/user/auth/email
     * @secure
     */
    emailUpdate: (data: EmailUpdateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/auth/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name EmailVerificationComplete
     * @request POST:/user/auth/email-verification/{token}
     * @secure
     */
    emailVerificationComplete: (token: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/auth/email-verification/${token}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name EmailVerificationStart
     * @request POST:/user/auth/email-verification
     * @secure
     */
    emailVerificationStart: (data: EmailVerifyBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/user/auth/email-verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PasswordUpdate
     * @request POST:/user/auth/password
     * @secure
     */
    passwordUpdate: (data: PasswordUpdateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/auth/password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserInfoUpdate
     * @request POST:/user/auth/info
     * @secure
     */
    userInfoUpdate: (data: UserInfoUpdateBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/auth/info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name CreateUserFeedback
     * @request POST:/user/feedback
     * @secure
     */
    createUserFeedback: (data: CreateUserFeedbackBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/feedback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserFeedbackList
     * @request GET:/user/feedback
     * @secure
     */
    getUserFeedbackList: (params: RequestParams = {}) =>
      this.request<GetUserFeedbackResponseDto, AmfResponseErrorDto>({
        path: `/user/feedback`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ExtensionLogStore
     * @request POST:/user/session/extension-log
     * @secure
     */
    extensionLogStore: (data: ExtensionLogStoreBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto>({
        path: `/user/session/extension-log`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name NotificationPatch
     * @request PATCH:/user/setting/notifications
     * @secure
     */
    notificationPatch: (data: NotificationPatchBodyDto, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorArrayDto | AmfResponseErrorDto>({
        path: `/user/setting/notifications`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name SessionDelete
     * @request DELETE:/user/setting/session/{sessionId}
     * @secure
     */
    sessionDelete: (sessionId: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/user/setting/session/${sessionId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name SessionList
     * @request GET:/user/setting/session
     * @secure
     */
    sessionList: (params: RequestParams = {}) =>
      this.request<SessionListResponseDto, AmfResponseErrorDto>({
        path: `/user/setting/session`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ZapierIntegrationDelete
     * @request DELETE:/user/zapier/{id}
     * @secure
     */
    zapierIntegrationDelete: (id: string, params: RequestParams = {}) =>
      this.request<EmptyResponse, AmfResponseErrorDto>({
        path: `/user/zapier/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ZapierIntegrationGetKey
     * @request GET:/user/zapier/{id}/key
     * @secure
     */
    zapierIntegrationGetKey: (id: string, params: RequestParams = {}) =>
      this.request<ZapierIntegrationGetKeyResponseDto, AmfResponseErrorDto>({
        path: `/user/zapier/${id}/key`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ZapierIntegrationList
     * @request GET:/user/zapier
     * @secure
     */
    zapierIntegrationList: (params: RequestParams = {}) =>
      this.request<ZapierIntegrationListResponseDto, AmfResponseErrorDto>({
        path: `/user/zapier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ZapierIntegrationNew
     * @request GET:/user/zapier/new
     * @secure
     */
    zapierIntegrationNew: (params: RequestParams = {}) =>
      this.request<ZapierKeyGetResponseDto, AmfResponseErrorDto>({
        path: `/user/zapier/new`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webhook = {
    /**
     * No description
     *
     * @name Mailcoach
     * @request POST:/webhook/mailcoach
     */
    mailcoach: (data: MailcoachBodyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhook/mailcoach`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Postmark
     * @request POST:/webhook/postmark
     */
    postmark: (data: PostmarkBodyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhook/postmark`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
