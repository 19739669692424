import clsx from "clsx";
import { formatDate } from "common/date";
import React from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";

import css from "./type.module.scss";

type Props = {
  endAt: string;
};

const SubscriptionTrialActive = ({ endAt }: Props) => {
  return (
    <div className={clsx(css.wrapper, css.blue)}>
      <div className={css.content}>
        <strong style={{ textDecoration: "none" }}>Trial Active</strong>:{" "}
        <Link
          className={css.link}
          style={{ textDecoration: "underline" }}
          to={paths.settingsBilling()}
        >
          Cancel
        </Link>{" "}
        before {formatDate(endAt)} or{" "}
        <Link
          className={css.link}
          style={{ textDecoration: "underline" }}
          to={paths.settingsBillingTrialConvert()}
        >
          Convert Now
        </Link>{" "}
        to remove limits.
      </div>
    </div>
  );
};

export default SubscriptionTrialActive;
