import React, { PropsWithChildren } from "react";
import { IntercomProvider as ReactUseIntercomProvider } from "react-use-intercom";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

const IntercomProvider = ({ children }: PropsWithChildren<{}>) => {
  const email = useAppSelector(authNewSelectors.userEmailGet);
  const name = useAppSelector(authNewSelectors.userNameGet);
  const userHash = useAppSelector(authNewSelectors.userIntercomHash);
  const userId = useAppSelector(authNewSelectors.userIdGet);

  return (
    <ReactUseIntercomProvider
      appId="k4to4j9j"
      autoBoot
      autoBootProps={
        userHash
          ? {
              email,
              name,
              userHash,
              userId,
            }
          : undefined
      }
    >
      {children}
    </ReactUseIntercomProvider>
  );
};

export default IntercomProvider;
