import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { SearchSingleDto, SearchSingleListResponseDto } from "api/Api";
import { useCallback } from "react";

const useHandleSearchSingleDelete = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (update: SearchSingleDto) => {
      queryClient.setQueriesData<InfiniteData<SearchSingleListResponseDto>>(
        { queryKey: ["search-single-list"] },
        (prevData) => {
          if (!prevData) return prevData;

          const pages = prevData.pages.map((page) => {
            const searchListFiltered = page.searchList.filter(
              ({ id }) => id !== update.id,
            );

            if (searchListFiltered.length !== page.searchList.length) {
              return {
                perPage: page.perPage - 1,
                searchList: searchListFiltered,
              };
            }

            return page;
          });

          return {
            pages,
            pageParams: prevData.pageParams,
          };
        },
      );
    },
    [queryClient],
  );
};

export default useHandleSearchSingleDelete;
