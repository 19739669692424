import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";

import css from "./type.module.scss";

const SubscriptionPastDue = () => {
  return (
    <div className={clsx(css.wrapper, css.red)}>
      <div className={css.content}>
        <Link className={css.link} to={paths.payNow()}>
          Oops! Your recent payment didn't go through. Please{" "}
          <strong>retry payment now</strong>.
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionPastDue;
