import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { authNewActions, authNewSelectors } from "store/reducers/auth-new";

const Rewardful = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(authNewSelectors.loggedInGet);

  useEffect(() => {
    if (!loggedIn) return;

    rewardful("ready", () => {
      dispatch(authNewActions.rewardfulReferrerSet(window.Rewardful.referral));
    });
  }, [dispatch, loggedIn]);

  return null;
};

export default Rewardful;
