import { Footer } from "components/footer";
import { Header } from "components/header";
import PromoTopAlert from "components/promo/top-alert";
import Rewardful from "components/rewardful";
import { SignupCompleteHandler } from "components/signup-complete-handler";
import TopAlert from "components/top-alert";
import Upscope from "components/upscope";
import WwwSearchHandler from "components/www-search-handler";
import Cookies from "js-cookie";
import Router from "routes/router";
import ScrollTopPageChange from "routes/scroll-top-page-change";

const App = () => {
  Cookies.set("amf_app_visited", "1", {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 365,
  });

  return (
    <>
      <Rewardful />
      <PromoTopAlert />
      <TopAlert />
      <Header />
      <Router />
      <Footer />
      <ScrollTopPageChange />
      <Upscope />
      <SignupCompleteHandler />
      <WwwSearchHandler />
    </>
  );
};

export default App;
