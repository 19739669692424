import { useStoreLogout } from "common/auth/hooks/use-logout";
import { useMemo } from "react";

import { Api } from "./Api";

type GetApiClientProps = {
  logout?: () => void | undefined;
};

export const getApiClient = ({ logout }: GetApiClientProps) => {
  return new Api({
    baseApiParams: { credentials: "include" },
    baseUrl: process.env.REACT_APP_HOST_APIAPP,
    customFetch: async (input: RequestInfo | URL, init?: RequestInit) => {
      const response = await fetch(input, init);

      if (logout && !response.ok && response.status === 401) {
        logout();
      }

      return response;
    },
  });
};

export const useGetApiClient = () => {
  const logout = useStoreLogout();

  return useMemo(() => getApiClient({ logout }), [logout]);
};
