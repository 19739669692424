import { Button, ButtonColor } from "components/button";
import { Content, Header, Modal } from "components/modal";
import { ReactNode } from "react";

import css from "./index.module.scss";

type Props = {
  closeButtonText?: string;
  confirmationButtonColor?: ButtonColor;
  confirmationButtonText?: string;
  confirmationMessage?: ReactNode;
  confirmationTitle?: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const ModalConfirm = ({
  closeButtonText = "Cancel",
  confirmationButtonColor = "red",
  confirmationButtonText = "Confirm",
  confirmationMessage = "Do you really wish to proceed? This process cannot be undone.",
  confirmationTitle = "Are you sure?",
  onClose: close,
  onConfirm: confirm,
}: Props) => {
  return (
    <Modal close={close} maxWidth={400}>
      <Header title={<strong>{confirmationTitle}</strong>} />
      <Content>
        <p className={css.description}>{confirmationMessage}</p>

        <div className={css.buttons}>
          <Button color="gray" onClick={() => close()}>
            {closeButtonText}
          </Button>
          <Button
            color={confirmationButtonColor}
            onClick={() => {
              confirm();
              close();
            }}
          >
            {confirmationButtonText}
          </Button>
        </div>
      </Content>
    </Modal>
  );
};
