export const numberShortened = (num: number): string => {
  const formats = [
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
  ];

  for (let i = 0; i < formats.length; i++) {
    if (num >= formats[i].value) {
      return (
        (num / formats[i].value).toFixed(1).replace(/\.0$/, "") +
        formats[i].symbol
      );
    }
  }

  return num.toString();
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const round = (number: number, digits: number = 2) =>
  Math.round(number * 10 ** digits) / 10 ** digits;
