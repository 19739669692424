import React from "react";

type Props = {
  className?: string;
  direction: "left" | "right" | "up" | "down";
};

export const CaretIcon = ({ className, direction }: Props) => {
  const rotation = {
    left: 90,
    right: -90,
    up: 180,
    down: 0,
  };

  return (
    <svg
      className={className}
      style={{ transform: `rotate(${rotation[direction]}deg)` }}
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
    </svg>
  );
};
