import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

// deprecated, use formatDate instead.
export const format = (dateString: string | null): string =>
  dateString ? dayjs.utc(dateString).format("YYYY-MM-DD") : "-";

export const formatDate = (date: string | Dayjs | null): string => {
  if (typeof date === "string") {
    return dayjs.utc(date).format("MMM DD");
  }
  if (date) {
    return date.format("MMM DD");
  }
  return "-";
};

export const formatWithTime = (dateString: string | null): string =>
  dateString ? dayjs.utc(dateString).format("YYYY-MM-DD HH:mm:ss") : "-";

export const toUtcIso = (date: Dayjs | undefined) =>
  date?.format("YYYY-MM-DDTHH:mm:ss.000Z");
