import React from "react";
type Props = { className?: string };

export const HamburgerIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="3" width="100%" />
      <rect height="3" width="100%" y="6" />
      <rect height="3" width="100%" y="12" />
    </svg>
  );
};
