import clsx from "clsx";
import { formatDate } from "common/date";
import React from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";

import css from "./type.module.scss";

type Props = {
  cancelAt: string;
};

const SubscriptionCanceling = ({ cancelAt }: Props) => {
  return (
    <div className={clsx(css.wrapper, css.yellow)}>
      <div className={css.content}>
        <Link className={css.link} to={paths.settingsBilling()}>
          Subscription ends {formatDate(cancelAt)}.{" "}
          <strong>Reactivate to save your credits!</strong>
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionCanceling;
