import React from "react";
import paths from "routes/paths";

import css from "./index.module.scss";

type Props = {
  className?: string;
};

export const Footer = ({ className }: Props) => {
  return (
    <footer className={[css.footer, className].join(" ")}>
      <div className={css.wrapper}>
        <div className={css.nav}>
          <a className={css.navItem} href={paths.help()}>
            Help center
          </a>
          <a className={css.navItem} href={paths.status()}>
            System status
          </a>
        </div>

        <div className={css.info}>
          © {new Date().getFullYear()} AMF Internet Services Limited
        </div>
      </div>
    </footer>
  );
};
