import useApiBillingRefill from "api/billing/use-api-billing-refill";
import getErrorMessage from "api/get-error-message";
import clsx from "clsx";
import { formatCurrency } from "common/currency";
import { ModalConfirm } from "components/modal-confirm";
import { useToast } from "contexts/toast";
import React, { useRef, useState } from "react";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import css from "./type.module.scss";

const SubscriptionCreditLow = () => {
  const creditLeft = useAppSelector(authNewSelectors.creditLeftGet);
  const subscription = useAppSelector(authNewSelectors.subscriptionGet);
  const loadingRef = useRef(false);
  const { addToast } = useToast();
  const { mutateAsync: refill } = useApiBillingRefill();
  const [confirmationModal, confirmationModalSet] = useState(false);

  const onRefill = async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;

    try {
      await refill();
      addToast("Your credits are being refilled...", {
        appearance: "success",
      });
    } catch (e) {
      addToast(getErrorMessage(e) || "An error occurred, please try again.", {
        appearance: "error",
      });
    }

    loadingRef.current = false;
  };

  if (!subscription) return null;

  const priceFormatted = formatCurrency(
    subscription.plan.cost / 100,
    subscription.plan.currency,
  );

  return (
    <div className={clsx(css.wrapper, css.yellow)}>
      <div className={css.content}>
        <a className={css.link} onClick={() => confirmationModalSet(true)}>
          You're {creditLeft === 0 ? "" : "running"} out of credits.{" "}
          <strong>Restart your billing cycle now</strong>.
        </a>
      </div>
      {confirmationModal && (
        <ModalConfirm
          confirmationButtonColor="green"
          confirmationButtonText="Refill credits"
          confirmationMessage={`By clicking "Refill Credits" your subscription billing cycle will be immediately renewed, and your account will be charged ${priceFormatted} for ${subscription.plan.creditPerPeriod} credits.`}
          confirmationTitle="Restart your billing cycle"
          onClose={() => confirmationModalSet(false)}
          onConfirm={onRefill}
        />
      )}
    </div>
  );
};

export default SubscriptionCreditLow;
