import { CrossIcon } from "icons/cross-icon";
import { HamburgerIcon } from "icons/hamburger-icon";
import React from "react";

import css from "./index.module.scss";

type Props = {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
};

export const Hamburger = ({ className, open, onClick }: Props) => {
  return (
    <button className={[css.button, className].join(" ")} onClick={onClick}>
      {open ? (
        <CrossIcon className={css.crossIcon} />
      ) : (
        <HamburgerIcon className={css.hamburgerIcon} />
      )}
    </button>
  );
};
