import clsx from "clsx";
import useClickOutsideHandler from "common/hooks/use-click-outside-handler";
import React, {
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import css from "./index.module.scss";

type PlacementType = "top" | "right" | "bottom" | "left";

type Props = {
  alwaysOpen?: true | false;
  backgroundColor?: "dark" | "light";
  distanceFromTrigger?: number;
  message: React.ReactNode;
  onClose?: () => void;
  placement: PlacementType;
  showLogic?: "hover" | "manual" | "click";
  tooltipClass?: string;
  triggerClass?: string;
};

const TOOLTIP_PLACEMENT = {
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
};

export const Tooltip = ({
  alwaysOpen = false,
  backgroundColor = "dark",
  children,
  distanceFromTrigger = 10,
  message,
  onClose,
  placement: initialPlacement = "bottom",
  showLogic = "hover",
  tooltipClass,
  triggerClass,
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 100, y: 100 });
  const [arrowPosition, setArrowPosition] = useState({ x: 100, y: 100 });
  const [placement, placementSet] = useState<PlacementType>(initialPlacement);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const getStyle = useCallback(
    (
      parentEl: HTMLElement,
      tooltipEl: HTMLElement,
      arrowEl: HTMLElement,
      placement: string,
    ) => {
      const parentRect = parentEl.getBoundingClientRect();
      const tooltipRect = tooltipEl.getBoundingClientRect();
      const arrowRect = arrowEl.getBoundingClientRect();

      // TOP (default)
      let style = {
        arrowLeft: parentRect.left + parentRect.width / 2 - arrowRect.width / 2,
        arrowTop: parentRect.top - distanceFromTrigger - arrowRect.height,
        tooltipLeft:
          parentRect.left + parentRect.width / 2 - tooltipRect.width / 2,
        tooltipTop:
          parentRect.top -
          distanceFromTrigger -
          tooltipRect.height -
          arrowRect.height,
      };

      if (placement === TOOLTIP_PLACEMENT.BOTTOM) {
        style = {
          arrowLeft:
            parentRect.left + parentRect.width / 2 - arrowRect.width / 2,
          arrowTop: parentRect.bottom + distanceFromTrigger,
          tooltipLeft:
            parentRect.left + parentRect.width / 2 - tooltipRect.width / 2,
          tooltipTop:
            parentRect.bottom + distanceFromTrigger + arrowRect.height,
        };
      }

      if (placement === TOOLTIP_PLACEMENT.LEFT) {
        style = {
          arrowLeft: parentRect.left - distanceFromTrigger - arrowRect.width,
          arrowTop:
            parentRect.top + parentRect.height / 2 - arrowRect.height / 2,
          tooltipLeft:
            parentRect.left -
            distanceFromTrigger -
            arrowRect.width -
            tooltipRect.width,
          tooltipTop:
            parentRect.top + parentRect.height / 2 - tooltipRect.height / 2,
        };
      }

      if (placement === TOOLTIP_PLACEMENT.RIGHT) {
        style = {
          arrowLeft: parentRect.right + distanceFromTrigger,
          arrowTop:
            parentRect.top + parentRect.height / 2 - arrowRect.height / 2,
          tooltipLeft: parentRect.right + distanceFromTrigger + arrowRect.width,
          tooltipTop:
            parentRect.top + parentRect.height / 2 - tooltipRect.height / 2,
        };
      }

      // check overflow
      if (
        [TOOLTIP_PLACEMENT.BOTTOM, TOOLTIP_PLACEMENT.TOP].includes(placement)
      ) {
        if (style.tooltipTop < 0) {
          placementSet(TOOLTIP_PLACEMENT.BOTTOM as PlacementType);
        }
        if (style.tooltipTop + tooltipRect.height >= window.innerHeight) {
          placementSet(TOOLTIP_PLACEMENT.TOP as PlacementType);
        }

        if (style.tooltipLeft < 0) {
          style.tooltipLeft = 10;
        }
        if (
          style.tooltipLeft + tooltipRect.width >=
          document.body.clientWidth
        ) {
          style.tooltipLeft =
            document.body.clientWidth - tooltipRect.width - 10;
        }
      }

      return style;
    },
    // position should update when tooltip gets closed and opened again
    // as well as when message and placement get changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, alwaysOpen, message, placement, distanceFromTrigger],
  );

  const closeTooltip = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  useClickOutsideHandler(triggerRef, closeTooltip, isOpen);

  useLayoutEffect(() => {
    const triggerEl = triggerRef.current;
    const tooltipEl = tooltipRef.current;
    const arrowEl = arrowRef.current;
    if (triggerEl === null || tooltipEl === null || arrowEl === null) return;

    const style = getStyle(triggerEl, tooltipEl, arrowEl, placement);
    setTooltipPosition({ x: style.tooltipLeft, y: style.tooltipTop });
    setArrowPosition({ x: style.arrowLeft, y: style.arrowTop });
  }, [getStyle, placement]);

  if (!message) return <>{children}</>;

  return (
    <>
      <div
        className={clsx(css.trigger, css[showLogic], triggerClass)}
        ref={triggerRef}
        onClick={() => showLogic === "click" && setIsOpen(!isOpen)}
        onMouseEnter={() => showLogic === "hover" && setIsOpen(true)}
        onMouseLeave={() => showLogic === "hover" && closeTooltip()}
      >
        {children}
      </div>

      {(isOpen || alwaysOpen) &&
        createPortal(
          <>
            <div
              className={clsx(css.tooltip, css[backgroundColor], tooltipClass)}
              ref={tooltipRef}
              style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
            >
              <div
                className={clsx(
                  placement === "bottom" && css.arrowUp,
                  placement === "top" && css.arrowDown,
                  placement === "right" && css.arrowLeft,
                  placement === "left" && css.arrowRight,
                )}
                ref={arrowRef}
                style={{ left: arrowPosition.x, top: arrowPosition.y }}
              />

              {message}
            </div>
          </>,
          document.body,
        )}
    </>
  );
};
