import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type SearchSinglePageStateType = {
  historyReady?: boolean;
  onboardingSearchId?: string;
  openedSearchIdList?: Record<string, boolean>;
  trackResultSearchIdList?: string[];
};

const initialState: SearchSinglePageStateType = {};

export const searchSinglePageSlice = createSlice({
  initialState,
  name: "searchSinglePage",
  reducers: {
    historyReadySet: (state, { payload }: PayloadAction<boolean>) => {
      state.historyReady = payload;
    },
    onboardingSearchIdSet: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.onboardingSearchId = payload;
    },
    openedSearchIdAdd: (state, { payload }: PayloadAction<string>) => {
      state.openedSearchIdList = {
        ...(state.openedSearchIdList || {}),
        [payload]: true,
      };
    },
    openedSearchIdClear: (state) => {
      state.openedSearchIdList = undefined;
    },
    openedSearchIdRemove: (state, { payload }: PayloadAction<string>) => {
      if (state.openedSearchIdList) {
        delete state.openedSearchIdList[payload];
      }

      state.openedSearchIdList = { ...(state.openedSearchIdList || {}) };
    },
    trackResultSearchIdAdd: (state, { payload }: PayloadAction<string>) => {
      state.trackResultSearchIdList = [
        ...(state.trackResultSearchIdList || []),
        payload,
      ];
    },
    trackResultSearchIdRemove: (state, { payload }: PayloadAction<string>) => {
      state.trackResultSearchIdList = (
        state.trackResultSearchIdList || []
      ).filter((i) => i !== payload);
    },
  },
});

export const searchSinglePageSelectors = {
  historyReadyGet: (state: RootState) =>
    state.searchSinglePage.historyReady === true,
  getOpenedSearchIdList: (state: RootState) =>
    state.searchSinglePage.openedSearchIdList || {},
  onboardingSearchIdGet: (state: RootState) =>
    state.searchSinglePage.onboardingSearchId,
  trackResultSearchIdListGet: (state: RootState) =>
    state.searchSinglePage.trackResultSearchIdList || [],
};

export const searchSinglePageActions = searchSinglePageSlice.actions;
export default searchSinglePageSlice.reducer;
