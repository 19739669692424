import useApiActivityLog from "api/activity-log/use-api-activity-log";
import { Button } from "components/button";
import { Content, Header, Modal } from "components/modal";
import { TextArea } from "components/textarea";
import { useToast } from "contexts/toast";
import { FormEvent, useState } from "react";

import css from "./index.module.scss";

type Props = {
  onClose: () => void;
};

export const ReferralModal = ({ onClose }: Props) => {
  const [referral, referralSet] = useState("");
  const { addToast } = useToast();
  const { mutate: activityLogMutate } = useApiActivityLog();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    activityLogMutate({
      description: JSON.stringify({ referral }),
      type: "auth_signup_modal",
    });

    addToast("Thank you for sharing!", { appearance: "success" });
    onClose();
  };

  return (
    <Modal>
      <Header close={false} title="How did you discover us?" />
      <Content>
        <form className={css.form} onSubmit={handleSubmit}>
          <TextArea
            placeholder='For example: Googling "email finder", a friend’s recommendation, ...'
            size="large"
            value={referral}
            onChange={(e) => referralSet(e.target.value)}
          />
          <Button size="large" type="submit">
            Submit
          </Button>
        </form>
      </Content>
    </Modal>
  );
};
