import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import { ReferralModal } from "./referral-modal";

export const COOKIE_AUTH_SIGNUP = "auth_signup";

export const SignupCompleteHandler = () => {
  const [referralModalShow, referralModalShowSet] = useState(false);
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);

  useEffect(() => {
    const cookie = Cookies.get(COOKIE_AUTH_SIGNUP);

    if (isLoggedIn && cookie === "true") {
      gtag("event", "conversion", {
        send_to: "AW-628742304/9UKHCLrQhcoZEKCx56sC",
      });
      referralModalShowSet(true);
    }

    Cookies.remove(COOKIE_AUTH_SIGNUP, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }, [isLoggedIn]);

  return (
    <>
      {referralModalShow && (
        <ReferralModal onClose={() => referralModalShowSet(false)} />
      )}
    </>
  );
};
