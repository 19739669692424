import { RefObject, useEffect } from "react";

const useClickOutsideHandler = (
  popupTriggerRef: RefObject<any>,
  onClose: () => void,
  enabled: boolean,
) => {
  useEffect(() => {
    if (!enabled) return;

    const handleClickOutside = (e: MouseEvent) => {
      const triggerEl = popupTriggerRef.current;

      if (triggerEl && !triggerEl.contains(e.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", onClose);
    window.addEventListener("resize", onClose);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", onClose);
      window.removeEventListener("resize", onClose);
    };
  }, [onClose, popupTriggerRef, enabled]);
};

export default useClickOutsideHandler;
