import React from "react";
type Props = { className?: string };

export const LogOutIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.16686 2.29922C2.93701 2.29922 2.71657 2.39053 2.55404 2.55306C2.3915 2.71559 2.3002 2.93603 2.3002 3.16589V14.8326C2.3002 15.0624 2.3915 15.2828 2.55404 15.4454C2.71657 15.6079 2.93701 15.6992 3.16686 15.6992H6.5002C6.94202 15.6992 7.3002 16.0574 7.3002 16.4992C7.3002 16.941 6.94202 17.2992 6.5002 17.2992H3.16686C2.51266 17.2992 1.88525 17.0393 1.42267 16.5767C0.960075 16.1142 0.700195 15.4868 0.700195 14.8326V3.16589C0.700195 2.51168 0.960075 1.88428 1.42267 1.42169C1.88525 0.959099 2.51266 0.699219 3.16686 0.699219H6.5002C6.94202 0.699219 7.3002 1.05739 7.3002 1.49922C7.3002 1.94105 6.94202 2.29922 6.5002 2.29922H3.16686Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.7665 4.26947C12.079 3.95705 12.5855 3.95705 12.8979 4.26947L17.0646 8.43614C17.377 8.74856 17.377 9.25509 17.0646 9.56751L12.8979 13.7342C12.5855 14.0466 12.079 14.0466 11.7665 13.7342C11.4541 13.4218 11.4541 12.9152 11.7665 12.6028L15.3675 9.00182L11.7665 5.40084C11.4541 5.08842 11.4541 4.58189 11.7665 4.26947Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.7002 8.99922C5.7002 8.55739 6.05837 8.19922 6.5002 8.19922H16.5002C16.942 8.19922 17.3002 8.55739 17.3002 8.99922C17.3002 9.44105 16.942 9.79922 16.5002 9.79922H6.5002C6.05837 9.79922 5.7002 9.44105 5.7002 8.99922Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
