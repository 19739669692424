import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authNew from "./reducers/auth-new";
import searchBulkPage from "./reducers/search-bulk-page";
import searchSinglePage from "./reducers/search-single-page";
import websocket from "./reducers/websocket";

const store = configureStore({
  reducer: {
    authNew,
    searchBulkPage,
    searchSinglePage,
    websocket,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
