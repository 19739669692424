import React from "react";
type Props = { className?: string };

export const ApiIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.575 5.64911C5.96111 5.25422 5.95399 4.62109 5.5591 4.23498C5.16421 3.84887 4.53109 3.85599 4.14498 4.25088L5.575 5.64911ZM0.900024 8.99999L0.185014 8.30088C-0.194972 8.68951 -0.194972 9.31048 0.185014 9.69911L0.900024 8.99999ZM4.14498 13.7491C4.53109 14.144 5.16421 14.1511 5.5591 13.765C5.95399 13.3789 5.96111 12.7458 5.575 12.3509L4.14498 13.7491ZM17.4551 4.25088C17.0689 3.85599 16.4358 3.84887 16.0409 4.23498C15.646 4.62109 15.6389 5.25422 16.025 5.64911L17.4551 4.25088ZM20.7 8.99999L21.415 9.69911C21.795 9.31048 21.795 8.68951 21.415 8.30088L20.7 8.99999ZM16.025 12.3509C15.6389 12.7458 15.646 13.3789 16.0409 13.765C16.4358 14.1511 17.0689 14.144 17.4551 13.7491L16.025 12.3509ZM7.84801 16.8625C7.71687 17.399 8.04547 17.9403 8.58196 18.0714C9.11845 18.2025 9.65967 17.8739 9.79081 17.3374L7.84801 16.8625ZM13.7508 1.13744C13.8819 0.600956 13.5533 0.0597349 13.0168 -0.0714056C12.4803 -0.202546 11.9391 0.126054 11.808 0.662543L13.7508 1.13744ZM4.14498 4.25088L0.185014 8.30088L1.61503 9.69911L5.575 5.64911L4.14498 4.25088ZM0.185014 9.69911L4.14498 13.7491L5.575 12.3509L1.61503 8.30088L0.185014 9.69911ZM16.025 5.64911L19.985 9.69911L21.415 8.30088L17.4551 4.25088L16.025 5.64911ZM19.985 8.30088L16.025 12.3509L17.4551 13.7491L21.415 9.69911L19.985 8.30088ZM9.79081 17.3374L13.7508 1.13744L11.808 0.662543L7.84801 16.8625L9.79081 17.3374Z" />
    </svg>
  );
};
