import clsx from "clsx";
import Seo from "components/seo";
import Spinner from "components/spinner";
import React, { PropsWithChildren } from "react";

import css from "./index.module.scss";

type Props = {
  centerVertically?: boolean;
  loading?: boolean;
  maxWidth?: string | number;
  title?: string;
};

const PageWrapper = ({
  centerVertically,
  children,
  loading,
  maxWidth = "100%",
  title,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={clsx(
        css.wrapper,
        (centerVertically || loading) && css.centerVertically,
      )}
    >
      <Seo title={title} />
      {loading ? (
        <Spinner />
      ) : (
        <div className={css.content} style={{ maxWidth }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default PageWrapper;
