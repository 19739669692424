export type Currency = "eur" | "gbp" | "usd";

export const formatCurrency = (amount: number, currency: Currency): string => {
  const hasDecimals = amount % 1 !== 0;

  return new Intl.NumberFormat("en-US", {
    currency: currency.toUpperCase(),
    minimumFractionDigits: 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
    style: "currency",
  }).format(amount);
};
