import { PlanPeriodType } from "common/billing/util";
import queryString from "query-string";

export type PathsPurchaseParams = {
  currency?: string;
  period?: PlanPeriodType;
  price?: number;
  repeat?: boolean;
  searchBulkId?: string;
  trial?: boolean;
};

const paths = {
  apiDocs: (): string =>
    `${process.env.REACT_APP_HOST_WWW}/email-finder-api/docs`,
  authLogin: (query: { successUrl?: string } = {}): string =>
    queryString.stringifyUrl({
      query,
      url: "/auth/login",
    }),
  authPasswordRecover: (): string => "/auth/password",
  authSignup: (query: { successUrl?: string } = {}): string =>
    queryString.stringifyUrl({
      query,
      url: "/sign_up",
    }),
  emailVerify: (): string => "/sign_up/verify/email/confirm",
  help: (): string => "https://help.anymailfinder.com/",
  invoiceFailedId: (
    id: string,
    query: { returnError?: boolean } = {},
  ): string =>
    queryString.stringifyUrl({
      query,
      url: `/invoice/${id}/failed`,
    }),
  invoiceFailedIdReturnStripe: (id: string): string =>
    `/invoice/${id}/failed/return/stripe`,
  legalApi: (): string =>
    `${process.env.REACT_APP_HOST_WWW}/legal#usage-of-the-api`,
  legalPrivacy: (): string => `${process.env.REACT_APP_HOST_WWW}/legal#privacy`,
  legalTerms: (): string => `${process.env.REACT_APP_HOST_WWW}/legal`,
  optOut: (): string => "/opt_out",
  payNow: (): string => "/settings/billing/pay_now",
  purchase: ({
    period,
    searchBulkId,
    trial = true,
  }: PathsPurchaseParams = {}): string =>
    queryString.stringifyUrl({
      query: {
        period,
        search_bulk_id: searchBulkId,
        trial: trial ? undefined : "0",
      },
      url: "/purchase",
    }),
  purchaseCheckout: (
    planId: string,
    {
      searchBulkId,
      trial = true,
      ...p
    }: PathsPurchaseParams & { returnError?: boolean } = {},
  ): string =>
    queryString.stringifyUrl({
      query: {
        ...p,
        search_bulk_id: searchBulkId,
        trial: trial ? undefined : "0",
      },
      url: `/purchase/checkout/${planId}`,
    }),
  purchaseReturnStripe: (
    planId: string,
    { currency, period, price, repeat, searchBulkId }: PathsPurchaseParams = {},
  ): string =>
    queryString.stringifyUrl({
      query: {
        currency,
        period,
        price,
        repeat,
        search_bulk_id: searchBulkId,
      },
      url: `/purchase/return/stripe/${planId}`,
    }),
  searchBulk: (searchId: string | undefined = undefined): string =>
    `/search/bulk${searchId ? `/${searchId}` : ""}`,
  searchSingle: (): string => "/search/single",
  settingsAccount: (): string => "/settings/account",
  settingsApi: (requestTrial = false): string =>
    `/settings/api${requestTrial ? "?request-trial=1" : ""}`,
  settingsAuth: (): string => "/settings/auth",
  settingsBilling: (): string => "/settings/billing",
  settingsBillingCancel: (): string => "/settings/billing/subscription/cancel",
  settingsBillingTrialConvert: (): string => "/settings/billing/trial-convert",
  settingsDelete: (): string => "/settings/delete-account",
  settingsIntegrations: (): string => "/settings/integrations",
  settingsIntegrationsConnect: (id: string): string =>
    `/settings/integrations/zapier/${id}/connect`,
  settingsTeam: (): string => "/settings/members/members",
  settingsUsage: (): string => "/settings/usage",
  status: (): string => "https://status.anymailfinder.com/",
  training: (lesson?: string): string =>
    `/training${lesson ? `/${lesson}` : ""}`,
  websiteHome: (): string =>
    process.env.REACT_APP_HOST_WWW || "https://www.anymailfinder.com",
};

export default paths;
