import clsx from "clsx";
import React, { cloneElement, MouseEvent, ReactElement } from "react";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "types/util/polymorphic";

import css from "./index.module.scss";

export type ButtonAppearanceType = "filled" | "outlined" | "transparent";
export type ButtonColor = "blue" | "dark" | "green" | "red" | "gray";
export type ButtonSize = "inner" | "small" | "medium" | "large";

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    appearance?: ButtonAppearanceType;
    className?: string;
    color?: ButtonColor;
    disabled?: boolean;
    fullWidth?: boolean;
    icon?: JSX.Element;
    iconPosition?: "left" | "right";
    loading?: boolean;
    onClick?: (e: MouseEvent) => void;
    size?: ButtonSize;
  }
>;

type ButtonType = <C extends React.ElementType = "button">(
  props: ButtonProps<C>,
  ref?: PolymorphicRef<C>,
) => React.ReactElement | null;

export const Button: ButtonType = React.forwardRef(
  <C extends React.ElementType = "button">(
    {
      appearance = "filled",
      as,
      children,
      className,
      color = "blue",
      disabled = false,
      fullWidth = false,
      icon: iconRaw,
      iconPosition = "left",
      loading = false,
      size = "medium",
      ...props
    }: ButtonProps<C>, // any or your default Component type
    ref?: PolymorphicRef<C>, // any or your default Component type
  ): ReactElement => {
    const Component = as || "button";
    const icon = iconRaw && cloneElement(iconRaw, { className: css.icon });

    const classNames = clsx(
      css.button,
      className,
      css[size],
      css[color],
      css[appearance],
      fullWidth && css.fullWidth,
      (disabled || loading) && css.disabled,
      !children && icon && css.iconOnly,
      loading && css.loading,
    );

    return (
      <Component
        className={classNames}
        disabled={disabled || loading}
        ref={ref}
        {...props}
      >
        {iconPosition === "left" && icon}
        {children}
        {iconPosition === "right" && icon}
        {loading && (
          <span className={css.spinnerWrapper}>
            <span className={css.spinner} />
          </span>
        )}
      </Component>
    );
  },
) as ButtonType;
