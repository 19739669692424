import React from "react";
type Props = { className?: string };

export const SearchIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.09998 2.35001C4.92434 2.35001 2.34998 4.92437 2.34998 8.10001C2.34998 11.2756 4.92434 13.85 8.09998 13.85C11.2756 13.85 13.85 11.2756 13.85 8.10001C13.85 4.92437 11.2756 2.35001 8.09998 2.35001ZM0.349976 8.10001C0.349976 3.8198 3.81977 0.350006 8.09998 0.350006C12.3802 0.350006 15.85 3.8198 15.85 8.10001C15.85 12.3802 12.3802 15.85 8.09998 15.85C3.81977 15.85 0.349976 12.3802 0.349976 8.10001Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.3429 12.3429C12.7334 11.9524 13.3666 11.9524 13.7571 12.3429L17.3571 15.9429C17.7476 16.3334 17.7476 16.9666 17.3571 17.3571C16.9666 17.7476 16.3334 17.7476 15.9429 17.3571L12.3429 13.7571C11.9523 13.3666 11.9523 12.7334 12.3429 12.3429Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
