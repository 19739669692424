import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";

import css from "./type.module.scss";

const SubscriptionPaused = () => {
  return (
    <div className={clsx(css.wrapper, css.yellow)}>
      <div className={css.content}>
        <Link className={css.link} to={paths.settingsBilling()}>
          Subscription Paused. <strong>Resume to use your credits</strong>.
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionPaused;
