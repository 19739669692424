import * as Sentry from "@sentry/react";
import useApiAuth from "api/auth/use-api-auth";
import PageError from "components/page-error";
import PageWrapper from "components/page-wrapper";
import React, { PropsWithChildren, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { authNewActions, authNewSelectors } from "store/reducers/auth-new";

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const authNewLoaded = useAppSelector(authNewSelectors._loadedGet);
  const dispatch = useAppDispatch();
  const { data, error } = useApiAuth();

  useEffect(() => {
    if (!data) return;

    dispatch(authNewActions._set(data));
    Sentry.setUser({ email: data.user?.email || undefined });
  }, [data, dispatch]);

  if (error) {
    return <PageError />;
  }

  if (!authNewLoaded) {
    return <PageWrapper loading />;
  }

  return <>{children}</>;
};

export default AuthProvider;
