import React from "react";
import { useLocation } from "react-router-dom";
import paths from "routes/paths";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import CreditLow from "./credit-low";
import SubscriptionCanceling from "./subscription-canceling";
import SubscriptionCreditLow from "./subscription-credit-low";
import SubscriptionPastDue from "./subscription-past-due";
import SubscriptionPaused from "./subscription-paused";
import SubscriptionTrialActive from "./subscription-trial-active";

const TopAlert = () => {
  const autoRefillThresholdPercentage = useAppSelector(
    authNewSelectors.teamSettingCreditAutoRefillThresholdPercentageGet,
  );
  const creditLeft = useAppSelector(authNewSelectors.creditLeftGet);
  const isTeamOwner = useAppSelector(authNewSelectors.userIsTeamOwner);
  const subscription = useAppSelector(authNewSelectors.subscriptionGet);
  const trialAvailable = useAppSelector(authNewSelectors.trialAvailableGet);
  const { pathname } = useLocation();

  if (
    pathname.startsWith("/purchase/return") ||
    pathname === paths.settingsBillingTrialConvert()
  ) {
    return null;
  }
  if (subscription) {
    if (subscription.cancelAt && isTeamOwner) {
      return <SubscriptionCanceling cancelAt={subscription.cancelAt} />;
    }

    if (subscription.pastDue) {
      return <SubscriptionPastDue />;
    }

    if (subscription.pauseEndAt && isTeamOwner) {
      return <SubscriptionPaused />;
    }

    if (subscription.trialEndAt && isTeamOwner) {
      return <SubscriptionTrialActive endAt={subscription.trialEndAt} />;
    }

    if (
      creditLeft <= Math.ceil((subscription.plan.creditPerPeriod / 100) * 10) &&
      autoRefillThresholdPercentage === null &&
      isTeamOwner
    ) {
      return <SubscriptionCreditLow />;
    }
  }

  if (!trialAvailable && creditLeft === 0 && isTeamOwner) {
    return <CreditLow />;
  }

  return null;
};

export default TopAlert;
