import clsx from "clsx";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import css from "./modal.module.scss";

type ModalContextType = {
  close?: () => void;
};

const ModalContext = createContext<ModalContextType>({});

export const useModalContext = () => useContext(ModalContext);

type Props = {
  centeredVertically?: boolean;
  close?: () => void;
  closeOverlay?: boolean;
  maxWidth?: number | string;
  overlayOpacity?: number;
  width?: string;
};

export const Modal = ({
  centeredVertically = true,
  children,
  close,
  closeOverlay = true,
  maxWidth = 536,
  overlayOpacity = 60,
  width = "100%",
}: PropsWithChildren<Props>) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!closeOverlay) return;
    e.stopPropagation();

    if (
      typeof close === "function" &&
      modalRef.current &&
      !modalRef.current.contains(e.target as any)
    ) {
      close();
    }
  };

  useEffect(() => {
    document.body.classList.add(css.body);
    return () => document.body.classList.remove(css.body);
  }, []);

  const context = useMemo(() => ({ close }), [close]);

  return createPortal(
    <ModalContext.Provider value={context}>
      <div
        className={css.overlay}
        style={{ background: `rgb(89, 105, 133, ${overlayOpacity}%)` }}
        onClick={onOverlayClick}
      >
        <div
          className={clsx(css.modal, centeredVertically && css.centered)}
          ref={modalRef}
          style={{ maxWidth, width }}
        >
          {children}
        </div>
      </div>
    </ModalContext.Provider>,
    document.body,
  );
};
