import clsx from "clsx";
import { CrossIcon } from "icons/cross-icon";
import React from "react";

import css from "./close.module.scss";
import { useModalContext } from "./index";

type Props = {
  inline?: boolean;
};

export const Close = ({ inline = false }: Props) => {
  const { close } = useModalContext();

  return (
    <button className={clsx(css.close, inline && css.inline)} onClick={close}>
      <CrossIcon className={css.cross} />
    </button>
  );
};
