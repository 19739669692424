import ToastItem, { ToastItemOptionType } from "contexts/toast/item";
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from "react";

import css from "./index.module.scss";
import useToastReducer from "./store";

type ToastContextType = {
  addToast: (content: ReactNode, options?: ToastItemOptionType) => any;
  closeAll: () => any;
  closeById: (id: number) => any;
};

export const ToastContext = createContext<ToastContextType>({
  addToast: () => {},
  closeAll: () => {},
  closeById: () => {},
});

export const ToastProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useToastReducer();

  const context: ToastContextType = useMemo(() => {
    return {
      addToast: (content: ReactNode, options) =>
        dispatch({
          payload: { content, options },
          type: "open",
        }),
      closeById: (id: number) =>
        dispatch({
          payload: id,
          type: "close",
        }),
      closeAll: () =>
        dispatch({
          payload: undefined,
          type: "close_all",
        }),
    };
  }, [dispatch]);

  return (
    <ToastContext.Provider value={context}>
      {children}

      <div className={css.wrapper}>
        {state.toastList.map(({ id, toast }) => (
          <ToastItem
            content={toast.content}
            id={id}
            key={id}
            options={toast.options}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
