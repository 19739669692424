export const CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/email-finder-by-anymailfi/jejkgijkhbdnekbbdonkikkfdmlfmdol";

export const getIsChromeExtensionInstalled = (): Promise<boolean> =>
  new Promise((resolve) => {
    const hasRuntime = "chrome" in window && Boolean(chrome && chrome.runtime);
    if (!hasRuntime) {
      resolve(false);
    }

    chrome.runtime.sendMessage(
      "jejkgijkhbdnekbbdonkikkfdmlfmdol",
      { message: "version" },
      (res) => {
        resolve(Boolean(res?.version));
      },
    );
  });
