import {
  AmfResponseErrorArrayDto,
  AmfResponseErrorDto,
  HttpResponse,
} from "./Api";
import { ResponseXhr } from "./get-api-client-xhr";

const getErrorMessage = (
  res: HttpResponse<unknown> | ResponseXhr<unknown> | unknown,
  messageDefault = "An error occurred. Please try again.",
): string => {
  if (res && typeof res === "object" && "error" in res) {
    const bodyError = res.error as
      | AmfResponseErrorArrayDto
      | AmfResponseErrorDto;

    if (Array.isArray(bodyError.message)) {
      return bodyError.message.join(", ");
    }

    return bodyError.message;
  }

  return messageDefault;
};

export default getErrorMessage;
