import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type WebsocketStateType = {
  connected?: boolean;
};

const initialState: WebsocketStateType = {};

export const websocketSlice = createSlice({
  initialState,
  name: "websocket",
  reducers: {
    connectedSet: (state, { payload }: PayloadAction<boolean>) => {
      state.connected = payload;
    },
  },
});

export const websocketSelectors = {
  connectedGet: (state: RootState) => state.websocket.connected === true,
};

export const websocketActions = websocketSlice.actions;
export default websocketSlice.reducer;
