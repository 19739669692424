import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

type Props = {
  className?: string;
  endAt: string;
  onEnd?: () => void;
};

const num = (n: number) => (n > 9 ? n : `0${n}`);

const format = (diffInSec: number): string => {
  const duration = dayjs.duration(diffInSec, "seconds");

  const pieces = [];
  if (duration.days() > 0) pieces.push(`${duration.days()}d`);
  if (duration.hours() > 0) pieces.push(`${duration.hours()}h`);
  pieces.push(`${num(duration.minutes())}m`);
  pieces.push(`${num(duration.seconds())}s`);

  return pieces.join(" ");
};

const Countdown = ({ className, endAt, onEnd }: Props) => {
  const [time, setTime] = useState<string>();

  useEffect(() => {
    const endAtUnix = dayjs.utc(endAt).unix();

    setTime(format(endAtUnix - dayjs.utc().unix()));

    const interval = setInterval(() => {
      const diffInSec = endAtUnix - dayjs.utc().unix();
      if (diffInSec <= 0) {
        clearInterval(interval);
        onEnd?.();
        return;
      }

      setTime(format(diffInSec));
    }, 1000);

    return () => clearInterval(interval);
  }, [endAt, onEnd]);

  return <span className={className}>{time}</span>;
};

export default Countdown;
