import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type SearchBulkPageStateType = {
  showTeamBulks: boolean;
};

const initialState: SearchBulkPageStateType = {
  showTeamBulks: false,
};

export const searchBulkPageSlice = createSlice({
  initialState,
  name: "searchBulkPage",
  reducers: {
    showTeamBulksSet: (state, { payload }: PayloadAction<boolean>) => {
      state.showTeamBulks = payload;
    },
  },
});

export const searchBulkPageSelectors = {
  showTeamBulksGet: (state: RootState) => state.searchBulkPage.showTeamBulks,
};

export const searchBulkPageActions = searchBulkPageSlice.actions;
export default searchBulkPageSlice.reducer;
