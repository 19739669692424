import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  plausibleTrackPageview: () => void;
};

const TrackPageview = ({ plausibleTrackPageview }: Props) => {
  const location = useLocation();

  useEffect(() => {
    plausibleTrackPageview();
  }, [location, plausibleTrackPageview]);

  return null;
};

export default TrackPageview;
