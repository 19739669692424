import { Button } from "components/button";
import { Tooltip } from "components/tooltip";
import { AMFLogo } from "icons/amf-logo";
import { CaretIcon } from "icons/caret-icon";
import { UserCircleIcon } from "icons/user-circle-icon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import { Credits } from "./credits";
import { Hamburger } from "./hamburger";
import css from "./index.module.scss";
import { MobileMenu } from "./mobile-menu";
import { NavMain } from "./nav/main";
import { NavUser } from "./nav/user";

type Props = {
  className?: string;
};

export const Header = ({ className }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);
  const name = useAppSelector(authNewSelectors.userNameGet);

  useEffect(() => {
    if (!mobileMenuOpen) return;
    document.body.classList.add(css.body);
    return () => document.body.classList.remove(css.body);
  }, [mobileMenuOpen]);

  return (
    <header
      className={[css.header, className].join(" ")}
      onClick={() => setMobileMenuOpen(false)}
    >
      <Link
        className={css.logo}
        to={isLoggedIn ? paths.searchSingle() : paths.websiteHome()}
      >
        <AMFLogo className={css.logoIcon} />
      </Link>

      <nav className={css.nav}>
        <NavMain
          iconClassname={css.navIcon}
          itemActiveClassname={css.active}
          itemClassname={css.navItem}
        />
      </nav>

      <div className={css.userInfo}>
        <div className={css.mobileOptions}>
          <Credits />

          <Hamburger
            className={css.hamburger}
            open={mobileMenuOpen}
            onClick={(e) => {
              e.stopPropagation(); // Without this, the click propagates to the header and closes the menu
              setMobileMenuOpen(!mobileMenuOpen);
            }}
          />
        </div>

        {isLoggedIn ? (
          <Tooltip
            backgroundColor="light"
            distanceFromTrigger={30}
            message={
              <NavUser
                iconClassname={css.userMenuIcon}
                itemActiveClassname={css.active}
                itemClassname={css.userMenuItem}
              />
            }
            placement="bottom"
            showLogic="click"
            tooltipClass={css.userMenu}
            triggerClass={css.userMenuTrigger}
          >
            <button
              className={css.user}
              onClick={() => setUserMenuOpen(!userMenuOpen)}
            >
              <UserCircleIcon className={css.avatar} />
              <span className={css.username}>{name}</span>
              <CaretIcon className={css.arrow} direction="down" />
            </button>
          </Tooltip>
        ) : (
          <Button
            appearance="outlined"
            as={Link}
            className={css.login}
            size="small"
            to={paths.authLogin()}
          >
            Login
          </Button>
        )}
      </div>

      {mobileMenuOpen && <MobileMenu className={css.mobileMenu} />}
    </header>
  );
};
