import React from "react";
type Props = { className?: string };

export const UserCircleIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C13.9711 18 18 13.9711 18 9C18 4.02891 13.9711 0 9 0ZM9 4.5C10.3982 4.5 11.5312 5.63344 11.5312 7.03125C11.5312 8.42906 10.3992 9.5625 9 9.5625C7.60219 9.5625 6.46875 8.42906 6.46875 7.03125C6.46875 5.63344 7.60078 4.5 9 4.5ZM9 15.75C7.13918 15.75 5.45273 14.9931 4.2293 13.7711C4.79883 12.3012 6.20508 11.25 7.875 11.25H10.125C11.7963 11.25 13.2026 12.3005 13.7707 13.7711C12.5473 14.9941 10.8598 15.75 9 15.75Z" />
    </svg>
  );
};
