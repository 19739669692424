import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetApiClient } from "api/get-api-client";

const useApiBillingRefill = () => {
  const apiClient = useGetApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await apiClient.billing.subscriptionRefill();
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["team-credit-left"],
      });
      queryClient.invalidateQueries({
        queryKey: ["team-credit-list"],
      });
    },
  });
};

export default useApiBillingRefill;
