import { Button } from "components/button";
import PageWrapper from "components/page-wrapper";
import { AMFLogo } from "icons/amf-logo";
import React from "react";
import { Link } from "react-router-dom";

import css from "./index.module.scss";

type Props = {
  button?: { text: string; to: string };
  code?: number;
  title?: string;
};

const PageError = ({ button, code, title: titleProp }: Props) => {
  let title: string | null = "An error occurred...";
  let message: string | null = "Please try again later.";

  if (code === 404) {
    title = "Not found.";
    message = null;
  }
  if (titleProp) {
    title = titleProp;
    message = null;
  }

  return (
    <PageWrapper centerVertically>
      <div className={css.wrapper}>
        <AMFLogo className={css.logo} />
        <strong className={css.title}>{title}</strong>
        {message && <span className={css.message}>{message}</span>}
        {button && (
          <Button as={Link} className={css.button} size="small" to={button.to}>
            {button.text}
          </Button>
        )}
      </div>
    </PageWrapper>
  );
};

export default PageError;
