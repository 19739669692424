import { useQueryClient } from "@tanstack/react-query";
import browserStorage from "common/browser-storage";
import { Button } from "components/button";
import Countdown from "components/countdown";
import { CrossIcon } from "icons/cross-icon";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import paths from "routes/paths";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import css from "./index.module.scss";

const getLsKeyHide = (id: string) => `promo:${id}:hide`;

const PromoTopAlert = () => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const promo = useAppSelector(authNewSelectors.promoGet);
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    if (promo) {
      setVisible(browserStorage.local.getItem(getLsKeyHide(promo.id)) !== "1");
    }
  }, [promo]);

  if (!promo || !visible) {
    return null;
  }

  const hide = () => {
    browserStorage.local.setItem(getLsKeyHide(promo.id), "1");
    setVisible(false);
  };
  const onEnd = () => {
    queryClient.refetchQueries({
      queryKey: ["auth-new"],
    });
    queryClient.refetchQueries({
      queryKey: ["billing-plan-list"],
    });

    if (pathname.startsWith("/purchase/checkout")) {
      navigate(paths.purchase());
    }
  };

  return (
    <div className={css.wrapper}>
      <Link className={css.content} to={paths.purchase()}>
        <span className={css.title}>
          <span className={css.emoticon}>🔥</span>
          <span>{promo.name}</span>
          <span className={css.emoticon}>🔥</span>
        </span>
        {promo.endAt && (
          <Countdown className={css.timer} endAt={promo.endAt} onEnd={onEnd} />
        )}
      </Link>
      <div className={css.close}>
        <Button
          appearance="transparent"
          className={css.closeButton}
          color="dark"
          icon={<CrossIcon />}
          size="inner"
          onClick={hide}
        />
      </div>
    </div>
  );
};

export default PromoTopAlert;
