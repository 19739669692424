import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import {
  SearchBulkListResponseDto,
  SearchBulkListResponseItemDto,
} from "api/Api";
import { chunk } from "common/array";
import { useCallback } from "react";
import { useAppDispatch } from "store";
import { authNewSelectors } from "store/reducers/auth-new";
import { searchBulkPageSelectors } from "store/reducers/search-bulk-page";

const useHandleSearchBulkUpdate = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useCallback(
    (update: SearchBulkListResponseItemDto) => {
      if (["on_deck"].includes(update.search.status)) return;

      const handleUpdate = dispatch((_, getState) => {
        const authUserId = authNewSelectors.userIdGet(getState());
        const showTeamBulks =
          searchBulkPageSelectors.showTeamBulksGet(getState());

        return update.search.userId === authUserId || showTeamBulks;
      });
      if (!handleUpdate) return;

      queryClient.invalidateQueries({
        queryKey: ["search-bulk", update.search.id],
      });

      queryClient
        .getQueriesData({
          queryKey: ["search-bulk-list"],
        })
        .forEach(([queryKey]) => {
          queryClient.setQueriesData<InfiniteData<SearchBulkListResponseDto>>(
            { queryKey },
            (prevData, ...p) => {
              if (!prevData) return prevData;

              let found = false;
              const perPage = prevData.pages[0].perPage;
              const searchList = prevData.pages
                .map((page) => page.searchList)
                .flat()
                .map((item) => {
                  if (item.search.id === update.search.id) {
                    found = true;
                    return update;
                  }
                  return item;
                });

              // append the search only on the base query, not on filtered queries
              if (!found && queryKey[1] === "") {
                searchList.unshift(update);
              }

              const pages = chunk<SearchBulkListResponseItemDto>(
                searchList,
                perPage,
              )
                .slice(0, prevData.pageParams.length)
                .map((searchList) => ({
                  perPage,
                  searchList,
                }));

              return {
                pages,
                pageParams: prevData.pageParams,
              };
            },
          );
        });
    },
    [dispatch, queryClient],
  );
};

export default useHandleSearchBulkUpdate;
