import { numberWithCommas } from "common/number";
import { Button } from "components/button";
import { Tooltip } from "components/tooltip";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import { useAppSelector } from "store";
import { authNewSelectors } from "store/reducers/auth-new";

import css from "./index.module.scss";

export const Credits = () => {
  const countActive = useAppSelector(authNewSelectors.creditLeftActiveGet);
  const countRollover = useAppSelector(authNewSelectors.creditLeftRolloverGet);
  const isLoggedIn = useAppSelector(authNewSelectors.loggedInGet);

  return (
    <div className={css.wrapper}>
      {isLoggedIn && (
        <span className={css.credits}>
          <span>Credits:</span>
          <strong>{numberWithCommas(countActive)}</strong>{" "}
          {countRollover > 0 && (
            <Tooltip
              message="These are the credits we rolled over from your previous subscription periods."
              placement="bottom"
              triggerClass={css.rollover}
            >
              (+ {numberWithCommas(countRollover)})
            </Tooltip>
          )}
        </span>
      )}
      <Button
        as={Link}
        className={css.button}
        size="small"
        to={paths.purchase()}
      >
        Add Credits
      </Button>
    </div>
  );
};
