import React from "react";
type Props = { className?: string };

export const VideoIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M19.4 2H4C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H19.4C20.5046 16 21.4 15.1046 21.4 14V4C21.4 2.89543 20.5046 2 19.4 2ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H19.4C21.6091 18 23.4 16.2091 23.4 14V4C23.4 1.79086 21.6091 0 19.4 0H4Z"
        fillRule="evenodd"
      />
      <path d="M9 11.6958V6.30425C9 5.51881 9.86395 5.03997 10.53 5.45625L14.8432 8.152C15.4699 8.54367 15.4699 9.45633 14.8432 9.848L10.53 12.5438C9.86395 12.96 9 12.4812 9 11.6958Z" />
    </svg>
  );
};
