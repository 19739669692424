import React from "react";
type Props = { className?: string };

export const AMFLogo = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0.587618 7.80444C0.587618 7.80444 8.91839 13.6647 12.2388 14.4635C10.6402 16.3526 6.20709 20.0619 6.26619 20.0243C10.9903 17.2283 20.9073 8.83454 20.9073 8.83454C21.2148 8.63845 21.5327 8.78397 21.6167 9.15687L23.9845 19.6723C24.0684 20.0452 23.8038 20.4088 23.3925 20.4845L4.35942 23.9902C3.94852 24.0658 3.51857 23.6971 3.39908 23.1664L0.0298016 8.20343C-0.0896779 7.67286 0.160941 7.49689 0.587618 7.80444ZM1.62254 6.28006C1.62254 6.28006 12.4639 -0.245055 13.7736 0.0071258C15.0521 0.253303 20.0194 7.18027 20.2552 7.50999L20.2639 7.52214C20.4615 7.7427 20.3315 7.90345 19.9732 7.88212L1.55715 6.7917C1.19832 6.77056 1.22838 6.54061 1.62254 6.28006Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
