import { useMutation } from "@tanstack/react-query";
import { SearchSingleCreateBodyDto } from "api/Api";
import { useGetApiClient } from "api/get-api-client";
import { useAppDispatch } from "store";
import { searchSinglePageActions } from "store/reducers/search-single-page";

const useApiSearchSingleCreate = () => {
  const apiClient = useGetApiClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async (data: SearchSingleCreateBodyDto) => {
      return await apiClient.search.searchSingleCreate(data);
    },
    onSuccess: ({ data }) => {
      dispatch(searchSinglePageActions.openedSearchIdAdd(data.id));
      dispatch(searchSinglePageActions.trackResultSearchIdAdd(data.id));
    },
  });
};

export default useApiSearchSingleCreate;
