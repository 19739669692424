import { ReactNode } from "react";
import React from "react";
import { Navigate } from "react-router-dom";

import paths from "./paths";

type RouteAuthType =
  | "guest-only"
  | "require-team-owner" // includes email verified
  | "required";

export type RouteType = {
  auth?: RouteAuthType;
  authRequiredRedirectTo?: "login" | "signup";
  children?: Record<string, RouteType>;
  component: ReactNode;
};

const AuthEmailVerificationTokenPage = React.lazy(
  () => import("pages/auth/email-verification/token"),
);
const AuthLoginPage = React.lazy(() => import("pages/auth/login"));
const AuthPasswordPage = React.lazy(() => import("pages/auth/password"));
const AuthPasswordTokenPage = React.lazy(
  () => import("pages/auth/password/token"),
);
const AuthSignupMemberPage = React.lazy(
  () => import("pages/auth/signup-member"),
);
const AuthSignupPage = React.lazy(() => import("pages/auth/signup"));
const ChargeInvoicePage = React.lazy(
  () => import("pages/charge/charge-invoice"),
);
const ExtensionLoginPage = React.lazy(() => import("pages/extension/login"));
const ExtensionOnboardingPage = React.lazy(
  () => import("pages/extension/onboarding"),
);
const ExtensionUninstallPage = React.lazy(
  () => import("pages/extension/uninstall"),
);
const InvoiceIdPage = React.lazy(() => import("pages/invoice/id"));
const InvoiceIdReturnStripePage = React.lazy(
  () => import("pages/invoice/id/return-stripe"),
);
const InvoicePayNowPage = React.lazy(() => import("pages/invoice/pay-now"));
const OptOutPage = React.lazy(() => import("pages/opt-out"));
const OptOutDomainTokenPage = React.lazy(
  () => import("pages/opt-out/domain/token"),
);
const OptOutEmailTokenPage = React.lazy(
  () => import("pages/opt-out/email/token"),
);
const PurchaseCheckoutPage = React.lazy(
  () => import("pages/purchase/checkout"),
);
const PurchasePage = React.lazy(() => import("pages/purchase/index"));
const PurchasePlansPage = React.lazy(() => import("pages/purchase/plans"));
const PurchaseReturnStripePage = React.lazy(
  () => import("pages/purchase/return/stripe"),
);
const SearchBulkPage = React.lazy(() => import("pages/search-bulk"));
const SearchSinglePage = React.lazy(() => import("pages/search-single"));
const TrainingIndexPage = React.lazy(() => import("pages/training"));
const TrainingLessonPage = React.lazy(() => import("pages/training/lesson"));
const SettingsPage = React.lazy(() => import("pages/settings/page"));
const SettingsAccountPage = React.lazy(() => import("pages/settings/account"));
const SettingsApiPage = React.lazy(() => import("pages/settings/api"));
const SettingsAuthPage = React.lazy(() => import("pages/settings/auth"));
const SettingsBillingPage = React.lazy(() => import("pages/settings/billing"));
const SettingsBillingTrialConvert = React.lazy(
  () => import("pages/settings/billing-trial-convert"),
);
const SettingsDeletePage = React.lazy(() => import("pages/settings/delete"));
const SettingsIntegrationAddPage = React.lazy(
  () => import("pages/settings/integration/add"),
);
const SettingsIntegrationListPage = React.lazy(
  () => import("pages/settings/integration/list"),
);
const SettingsIntegrationConnectPage = React.lazy(
  () => import("pages/settings/integration/connect"),
);
const SettingsTeamPage = React.lazy(() => import("pages/settings/team"));
const SettingsUsagePage = React.lazy(() => import("pages/settings/usage"));

const routeList: Record<string, RouteType> = {
  "/": { component: <Navigate to={paths.searchSingle()} /> },
  "/auth/login": { auth: "guest-only", component: <AuthLoginPage /> },
  "/auth/invites/:token": { component: <AuthSignupMemberPage /> },
  "/opt_out": { component: <OptOutPage /> },
  "/opt_out/domain/:token": { component: <OptOutDomainTokenPage /> },
  "/opt_out/email/:token": { component: <OptOutEmailTokenPage /> },
  "/sign_up": { auth: "guest-only", component: <AuthSignupPage /> },
  "/sign_up/verify": { component: <Navigate to={paths.searchSingle()} /> },
  "/sign_up/verify/email/confirm": {
    component: <AuthEmailVerificationTokenPage />,
  },
  "/sign_up/verify/email/confirm/:token": {
    component: <AuthEmailVerificationTokenPage />,
  },
  "/auth/password": { component: <AuthPasswordPage /> },
  "/auth/password/:token": { component: <AuthPasswordTokenPage /> },
  "/billing/charge/:charge_id/invoice": { component: <ChargeInvoicePage /> },
  "/extension/login": { component: <ExtensionLoginPage /> },
  "/extension/onboarding": { component: <ExtensionOnboardingPage /> },
  "/extension/uninstall": { component: <ExtensionUninstallPage /> },
  "/invoice/:id/failed": { component: <InvoiceIdPage /> },
  "/invoice/:id/failed/return/stripe": {
    component: <InvoiceIdReturnStripePage />,
  },
  "/search/single": {
    component: <SearchSinglePage />,
  },
  "/search/bulk": {
    component: <SearchBulkPage />,
  },
  "/search/bulk/:search_id": {
    auth: "required",
    component: <SearchBulkPage />,
  },
  "/training": {
    component: <TrainingIndexPage />,
  },
  "/training/:id": {
    component: <TrainingLessonPage />,
  },
  "/delete-account": { component: <Navigate to={paths.settingsDelete()} /> },
  "/usage": { component: <Navigate to={paths.settingsUsage()} /> },
  "/settings": {
    auth: "required",
    children: {
      "/settings": { component: <Navigate to={paths.settingsAccount()} /> },
      "/settings/account": {
        auth: "required",
        component: <SettingsAccountPage />,
      },
      "/settings/auth": {
        auth: "required",
        component: <SettingsAuthPage />,
      },
      "/settings/billing": {
        auth: "require-team-owner",
        component: <SettingsBillingPage />,
      },
      "/settings/billing/subscription/cancel": {
        auth: "require-team-owner",
        component: <SettingsBillingPage cancel />,
      },
      "/settings/delete-account": {
        auth: "required",
        component: <SettingsDeletePage />,
      },
      "/settings/members/members": {
        auth: "require-team-owner",
        component: <SettingsTeamPage />,
      },
      "/settings/usage": {
        auth: "require-team-owner",
        component: <SettingsUsagePage />,
      },
      "/settings/integrations": {
        auth: "required",
        component: <SettingsIntegrationListPage />,
      },
    },
    component: <SettingsPage />,
  },
  "/settings/api": {
    component: <SettingsApiPage />,
  },
  "/settings/billing/pay_now": {
    auth: "required",
    component: <InvoicePayNowPage />,
  },
  "settings/billing/trial-convert": {
    auth: "require-team-owner",
    component: <SettingsBillingTrialConvert />,
  },
  "/settings/integrations/add/zapier": {
    auth: "required",
    component: <SettingsIntegrationAddPage />,
  },
  "/settings/integrations/zapier/:id/connect": {
    auth: "required",
    component: <SettingsIntegrationConnectPage />,
  },
  "/purchase": {
    children: {
      "/purchase": { component: <PurchasePlansPage /> },
      "/purchase/checkout/:id": {
        auth: "required",
        authRequiredRedirectTo: "signup",
        component: <PurchaseCheckoutPage />,
      },
      "/purchase/return/stripe/:id": {
        component: <PurchaseReturnStripePage />,
      },
    },
    component: <PurchasePage />,
  },
};

export default routeList;
