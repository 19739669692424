import clsx from "clsx";
import { uppercaseFirst } from "common/string";
import { useToast } from "contexts/toast";
import { CheckmarkIcon } from "icons/checkmark-icon";
import { CrossIcon } from "icons/cross-icon";
import { InfoIcon } from "icons/info-icon";
import React, { ReactNode, useEffect } from "react";

import css from "./index.module.scss";

const AUTO_DISMISS_MS = 2500;

export type ToastItemOptionType = {
  appearance: "success" | "error" | "info";
  autoDismiss?: boolean;
  title?: ReactNode;
};

type Props = {
  content: ReactNode;
  id: number;
  options?: ToastItemOptionType;
};

const ToastItem = ({
  content,
  id,
  options = { appearance: "success" },
}: Props) => {
  const { appearance, autoDismiss = true, title } = options;
  const { closeById } = useToast();

  useEffect(() => {
    if (!autoDismiss) return;
    setTimeout(() => closeById(id), AUTO_DISMISS_MS);
  }, [autoDismiss, closeById, id]);

  return (
    <div
      className={clsx(css.toast, css[appearance])}
      onClick={() => closeById(id)}
    >
      {appearance === "success" && <CheckmarkIcon className={css.icon} />}
      {appearance === "info" && <InfoIcon className={css.icon} />}
      {appearance === "error" && <CrossIcon className={css.icon} />}

      <div>
        <strong className={css.title}>
          {title || uppercaseFirst(appearance)}
        </strong>
        <span className={css.text}>{content}</span>
      </div>
    </div>
  );
};

export default ToastItem;
