import { getIsChromeExtensionInstalled } from "common/extension/chrome";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type ExtensionContextType = {
  installed: boolean;
};

const ExtensionContext = createContext<ExtensionContextType>({
  installed: false,
});

export const useExtensionContext = () => {
  return useContext(ExtensionContext);
};

export const ExtensionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [installed, installedSet] = useState(false);

  useEffect(() => {
    getIsChromeExtensionInstalled().then((v) => installedSet(v));
  }, []);

  const context = useMemo<ExtensionContextType>(
    () => ({ installed }),
    [installed],
  );

  return (
    <ExtensionContext.Provider value={context}>
      {children}
    </ExtensionContext.Provider>
  );
};
