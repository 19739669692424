import usePrevious from "common/hooks/use-prev";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import paths from "./paths";

const ScrollTopPageChange = () => {
  const { search, pathname } = useLocation();

  const pathNamePrev = usePrevious(pathname, "");

  useEffect(() => {
    if (
      pathNamePrev.startsWith(paths.searchBulk()) &&
      pathname.startsWith(paths.searchBulk())
    ) {
      return;
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [search, pathname, pathNamePrev]);

  return null;
};

export default ScrollTopPageChange;
